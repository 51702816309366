import { WishlistViewMode } from "@leadbay/typings"
import { useState } from "react"
import { toast } from "react-toastify"
import { LeadStatus, usePostLeadsByLeadIdSetStatusMutation } from "../../api"

interface ChangeLeadStatusProps {
  leadId: string
  wishlistViewMode: WishlistViewMode
}

export const useChangeLeadStatus = ({
  leadId,
  wishlistViewMode,
}: ChangeLeadStatusProps) => {
  const [optimisticState, setOptimisticState] = useState<LeadStatus>()

  const [useStatusMutation, { isLoading }] =
    usePostLeadsByLeadIdSetStatusMutation()

  const handleUpdateLeadStatus = async (status: LeadStatus) => {
    try {
      const payload = {
        leadId,
        setStatusRequest: {
          status,
        },
      }

      await useStatusMutation(payload).unwrap()

      setOptimisticState(status)

      const successMessage =
        wishlistViewMode === WishlistViewMode.DISCOVER
          ? "Lead status updated and moved to the monitor"
          : "Lead status updated"

      toast.success(successMessage)
    } catch (error) {
      console.error(error)

      toast.error("Failed to update lead status")
    }
  }

  return {
    handleUpdateLeadStatus,
    optimisticState,
    isLoading,
  }
}
