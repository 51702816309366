import { LbWishlistGrid } from "@components/display/LbWishlistGrid/LbWishlistGrid"
import { LbCloseDrawer } from "@components/inputs/LbCloseDrawer/LbCloseDrawer"
import {
  useAppSelector,
  useKeyboardController,
  useWishlistData,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { generateWishlistRows } from "@leadbay/utils"
import { Box } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

export const Discover = () => {
  const [rowCountState, setRowCountState] = useState(0)

  const { wishlistViewMode, pageSize } = useAppSelector(selectCommonsState)

  const {
    wishlistData,
    isMonitorFetching: isFetching,
    checkWishlistComputing,
    wishlistPaginationModel,
    setWishlistPaginationModel,
  } = useWishlistData({
    status: undefined,
  })

  const rows = useMemo(
    () => generateWishlistRows(wishlistData?.items ?? []),
    [wishlistData],
  )

  const { dataGridRef } = useKeyboardController({
    page: wishlistPaginationModel.page,
    rows,
  })

  useEffect(() => {
    if (pageSize) {
      setWishlistPaginationModel({
        pageSize,
        page: 0,
      })
    }
  }, [wishlistViewMode, pageSize])

  useEffect(() => {
    const total = wishlistData?.pagination.total

    if (total !== undefined) {
      setRowCountState(total)
    }
  }, [wishlistData, wishlistViewMode])

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <LbWishlistGrid
        dataGridRef={dataGridRef}
        rows={rows}
        isFetching={isFetching}
        rowCount={rowCountState}
        showLoader={checkWishlistComputing}
        gridSubject={WishlistViewMode.DISCOVER}
        paginationModel={wishlistPaginationModel}
        setPaginationModel={setWishlistPaginationModel}
      />

      <LbCloseDrawer />
    </Box>
  )
}
