import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  selectCommonsState,
  toggleNavDrawer,
} from "@leadbay/state/slices/commonsSlice"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { Box } from "@mui/system"

export const LbCloseDrawer = () => {
  const dispatch = useAppDispatch()

  const { navDrawerIsOpen, navDrawerPartial } =
    useAppSelector(selectCommonsState)

  const handleToggleNavDrawer = () => {
    dispatch(
      toggleNavDrawer({
        isOpen: !navDrawerIsOpen,
        partial: navDrawerPartial,
      }),
    )
  }

  return (
    <Box
      onClick={handleToggleNavDrawer}
      sx={{
        position: "absolute",
        top: "50%",
        right: "-35px",
        transform: "translateY(-50%)",
        zIndex: 9999,
      }}
    >
      <IconButton color="primary">
        {navDrawerIsOpen ? (
          <ChevronRight style={{ fontSize: "1.8rem" }} />
        ) : (
          <ChevronLeft style={{ fontSize: "1.8rem" }} />
        )}
      </IconButton>
    </Box>
  )
}
