import { Box, Tooltip, Typography } from "@mui/material"
import React from "react"

interface LbLeadInfosContactProps {
  websiteProperties: {
    email_address?: string[]
    contact_url?: string[]
    phone_number?: string[]
  }
  handleCopyToClipboard: (text: string) => void
}

export const LbLeadInfosContact: React.FC<LbLeadInfosContactProps> = ({
  websiteProperties,
  handleCopyToClipboard,
}) => {
  const [haveContact, setHaveContact] = React.useState(false)

  React.useEffect(() => {
    if (
      (websiteProperties?.email_address?.length ?? 0) > 0 ||
      (websiteProperties?.phone_number?.length ?? 0) > 0
    ) {
      setHaveContact(true)
    } else {
      setHaveContact(false)
    }
  }, [websiteProperties])

  return (
    <>
      {haveContact && (
        <Box>
          <Typography
            fontWeight={900}
            variant="subtitle1"
            fontFamily="Hanken Grotesk"
            color="text.secondary"
            sx={{ mb: 2 }}
          >
            Contact
          </Typography>
          {(websiteProperties?.email_address?.length ?? 0) > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                my: 2,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                📧
              </Box>

              <Box>
                {websiteProperties?.email_address?.map((email: string) => (
                  <Tooltip title={`Copy to clipboard`} key={email}>
                    <Typography
                      onClick={() => handleCopyToClipboard(email)}
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {email}
                    </Typography>
                  </Tooltip>
                ))}
              </Box>
            </Box>
          )}

          {(websiteProperties?.phone_number?.length ?? 0) > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                my: 2,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                📞
              </Box>

              <Box>
                {websiteProperties?.phone_number?.map((phone) => (
                  <Tooltip title={`Copy to clipboard`} key={phone}>
                    <Typography
                      onClick={() => handleCopyToClipboard(phone)}
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {phone}
                    </Typography>
                  </Tooltip>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
