import { useCallback } from "react"
import { useAppDispatch } from ".."
import { Interaction, leadbayApi, usePostInteractionsMutation } from "../../api"

export const useRecordUserInteractions = () => {
  const dispatch = useAppDispatch()

  const [postInteractions] = usePostInteractionsMutation()

  const handleRecordUserInteractions = useCallback(
    async (interactions: Interaction[]) => {
      try {
        await postInteractions({ body: interactions })

        dispatch(leadbayApi.util.invalidateTags(["Leads", "Timeline"]))
      } catch (error) {
        console.error(error)
      }
    },
    [postInteractions],
  )

  return { handleRecordUserInteractions }
}
