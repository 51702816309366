import { LbFade } from "@components/animations/LbFade/LbFade"
import { LbCopyright } from "@components/display/LbCopyright/LbCopyright"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import {
  CENTERED_FLEX_COL,
  CENTERED_FLEX_COL_BETWEEN,
  CENTERED_FLEX_COL_BETWEEN_START,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { Box, Container, Typography } from "@mui/material"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

export const AuthLayout = () => {
  const { user } = useAppSelector(selectAuthState)

  const location = useLocation()

  const isOnboardingProcess =
    location.pathname ===
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PRIVATE.ONBOARDING ||
    location.pathname ===
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.VERIFY_EMAIL ||
    user?.organization?.onboarding_step !== "FINISHED"

  if (user?.verified && !isOnboardingProcess) {
    const to =
      (location.state?.from as string | undefined) || WEB_APP_ROUTES.APP
    return <Navigate to={to} />
  }

  if (
    user?.verified &&
    location.pathname !==
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PRIVATE.ONBOARDING &&
    user?.organization?.onboarding_step !== "FINISHED"
  ) {
    return <Navigate to={WEB_APP_ROUTES.PRIVATE.ONBOARDING} />
  }

  let headline
  switch (location.pathname) {
    case WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.LOGIN:
      headline =
        "Welcome back to Leadbay, a model trained to find your next big deals. "
      break
    case WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.INVITE_REGISTER:
      headline =
        "Welcome to Leadbay, a model trained to find your next big deals. "
      break
    case WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.FORGOT_PASSWORD:
      headline =
        "Welcome back to Leadbay, a model trained to find your next big deals. "
      break
    case WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.RESET_PASSWORD:
      headline =
        "Welcome back to Leadbay, a model trained to find your next big deals. "
      break
    case WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.VERIFY_EMAIL:
      headline = "EMAIL VERIFICATION"
      break
    default:
      headline =
        "Welcome to Leadbay, a model trained to find your next big deals. "
      break
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          paddingLeft: "10vw",
          paddingTop: "20vh",
          width: "65vw",
          background:
            "linear-gradient(132.96deg, #CDCED8 8.68%, #DCFFDE 21.7%, #CDCED8 44.44%)",
        }}
      >
        <LbFade>
          <Box
            maxWidth={518}
            sx={{
              ...CENTERED_FLEX_COL_BETWEEN_START,
              height: "fit-content",
              gap: "88px",
            }}
          >
            <Link to={WEB_APP_ROUTES.ROOT}>
              <LBLogo />
            </Link>
            <Typography
              sx={{
                fontSize: "42px",
                fontWeight: "600",
                lineHeight: "54.73px",
                color: "#343643",
              }}
            >
              {headline}
            </Typography>
          </Box>
        </LbFade>
      </Box>

      <LbFade>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "35vw",
            height: "100vh",
            paddingTop: "15vh",
            margin: "auto",
          }}
        >
          <Outlet />
        </Box>
      </LbFade>
    </Box>
  )
}
