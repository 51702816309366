import { MessageType } from "@leadbay/typings"
import { useAsyncEffect } from "ahooks"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
  Interaction,
  LeadStatus,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
} from "../../api"
import { selectCommonsState } from "../../slices/commonsSlice"
import { useRecordUserInteractions } from "../analytics/useRecordUserInteractions"
import { useAppDispatch } from "../commons/useAppDispatch"
import { useAppSelector } from "../commons/useAppSelector"
import { useLeadbayWSNotification } from "../commons/useLeadbayWSNotification"

export interface PaginationModel {
  page: number
  pageSize: number
}

interface WishlistData {
  status?: LeadStatus
}

export const useWishlistData = ({ status }: WishlistData) => {
  const dispatch = useAppDispatch()

  const {
    currentLensId,
    pageSize,
    wishlistViewMode,
    currentFilter,
    sortModel,
  } = useAppSelector(selectCommonsState)

  const [checkWishlistComputing, setCheckWishlistComputing] = useState(false)
  const [checkMonitorComputing, setCheckMonitorComputing] = useState(false)
  const [pageViewHistory, setPageViewHistory] = useState<number[]>([])

  const [monitorPaginationModel, setMonitorPaginationModel] =
    useState<PaginationModel>({
      page: 0,
      pageSize: pageSize,
    })

  const [wishlistPaginationModel, setWishlistPaginationModel] =
    useState<PaginationModel>({
      page: 0,
      pageSize: pageSize,
    })

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { messages } = useLeadbayWSNotification(MessageType.IMPORT_PROGRESS)

  const {
    data: wishlistData,
    isFetching: isWishlistFetching,
    isLoading: isWishlistLoading,
    error: wishlistError,
    isUninitialized: isWishlistUninitialized,
    refetch: refetchWishlist,
  } = useGetLensesByLensIdLeadsWishlistQuery(
    {
      lensId: currentLensId,
      count: pageSize,
      page: wishlistPaginationModel.page,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkWishlistComputing ? 2000 : 1000 * 60 * 60,
    },
  )

  const {
    data: monitorData,
    isFetching: isMonitorFetching,
    isLoading: isMonitorLoading,
    error: monitorError,
    isUninitialized: isMonitorUninitialized,
    refetch: refetchMonitor,
  } = useGetLensesByLensIdLeadsQuery(
    {
      lensId: currentLensId,
      page: monitorPaginationModel.page,
      count: pageSize,
      wished: false,
      status,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkMonitorComputing ? 2000 : 1000 * 60 * 60,
    },
  )

  useAsyncEffect(async () => {
    const dataItems = monitorData?.items
    const interactions: Interaction[] =
      dataItems?.map((item) => ({
        type: "LEAD_SEEN",
        lead_id: item.id,
        lens_id: String(currentLensId),
      })) || []

    if (
      dataItems &&
      !checkWishlistComputing &&
      !isWishlistFetching &&
      !isMonitorFetching
    ) {
      if (!pageViewHistory.includes(monitorPaginationModel.page)) {
        await handleRecordUserInteractions(interactions)
        setPageViewHistory((prev) => [...prev, monitorPaginationModel.page])
      }

      if (!pageViewHistory.includes(wishlistPaginationModel.page)) {
        await handleRecordUserInteractions(interactions)
        setPageViewHistory((prev) => [...prev, wishlistPaginationModel.page])
      }
    }
  }, [
    monitorData?.items,
    monitorPaginationModel.page,
    wishlistPaginationModel.page,
    isMonitorFetching,
    isWishlistFetching,
    checkMonitorComputing,
    wishlistViewMode,
    pageViewHistory,
    currentLensId,
  ])

  useEffect(() => {
    const lastMessage = messages?.[messages.length - 1]

    if (
      lastMessage &&
      [MessageType.SCORES_UPDATED, MessageType.LENS_REFRESHED].includes(
        lastMessage.type,
      )
    ) {
      setCheckMonitorComputing(false)
      setCheckWishlistComputing(false)

      setWishlistPaginationModel({
        page: 0,
        pageSize: pageSize ?? 10,
      })

      setMonitorPaginationModel({
        page: 0,
        pageSize: pageSize ?? 10,
      })
    }

    const isRefetchDiscover =
      wishlistData?.computing_wishlist || wishlistData?.computing_scores

    setCheckWishlistComputing(!!isRefetchDiscover)

    const isRefetchMonitor = monitorData?.computing_scores

    setCheckMonitorComputing(!!isRefetchMonitor)
  }, [
    monitorData?.computing_scores,
    wishlistData?.computing_scores,
    pageSize,
    messages,
    dispatch,
  ])

  useEffect(() => {
    if (wishlistError || wishlistError) {
      setCheckMonitorComputing(false)
      setCheckWishlistComputing(false)

      toast.error("Error fetching wishlist data")

      console.error(wishlistError)
      console.error(monitorError)
    }
  }, [wishlistError, monitorError])

  const handleRefetchData = async () => {
    if (checkWishlistComputing || checkMonitorComputing) {
      return
    }

    if (isMonitorUninitialized) await refetchMonitor()

    if (isWishlistUninitialized) await refetchWishlist()
  }

  return {
    checkWishlistComputing,
    checkMonitorComputing,
    showLoader: checkWishlistComputing || checkMonitorComputing,
    wishlistData,
    monitorData,
    refetchWishlist,
    setMonitorPaginationModel,
    setWishlistPaginationModel,
    monitorPaginationModel,
    wishlistPaginationModel,
    refetchMonitor,
    handleRefetchData,
    isMonitorFetching,
    isWishlistFetching,
    isWishlistLoading,
    isMonitorLoading,
  }
}
