import {
  CENTERED_FLEX_COL,
  CENTERED_FLEX_ROW,
  WISHLIST_DISCOVER_VISIBILITY_MODEL,
  WISHLIST_MONITOR_VISIBILITY_MODEL,
} from "@leadbay/constants"
import { leadbayApi, type LeadOrder } from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useRecordUserInteractions,
} from "@leadbay/state/hooks"
import {
  NavDrawerState,
  selectCommonsState,
  setSelectedIds,
  setSortModel,
  toggleNavDrawer,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { TransformedEntry } from "@leadbay/utils/commons/generateWishListRows"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import {
  DataGrid,
  GridToolbarProps,
  ToolbarPropsOverrides,
  type GridColumnVisibilityModel,
  type GridPaginationModel,
  type GridRowParams,
  type GridSortModel,
} from "@mui/x-data-grid"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { JSXElementConstructor, useCallback, useState } from "react"
import { useWishlistColumns } from "./useWishlistColumns"

interface LbDataGridProps {
  rows: Array<TransformedEntry & { new: boolean }>
  rowCount?: number
  toolbar?:
    | JSXElementConstructor<GridToolbarProps & ToolbarPropsOverrides>
    | null
    | undefined
  showLoader?: boolean
  hideFooter?: boolean
  isFetching?: boolean
  dataGridRef?: React.RefObject<HTMLDivElement>
  gridSubject: WishlistViewMode
  paginationModel: GridPaginationModel
  setPaginationModel: (paginationModel: GridPaginationModel) => void
}

export const LbWishlistGrid = ({
  paginationModel,
  setPaginationModel,
  showLoader,
  toolbar,
  rows,
  rowCount,
  hideFooter,
  dataGridRef,
  gridSubject,
}: LbDataGridProps) => {
  const dispatch = useAppDispatch()

  const { wishlistViewMode, currentLensId, selectedIds } =
    useAppSelector(selectCommonsState)

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const handleSetPaginationModel = useCallback(
    (params: GridPaginationModel) => {
      const { page, pageSize } = params

      setPaginationModel({
        page,
        pageSize,
      })
      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    },
    [],
  )

  const handleRowClick = useCallback(
    async (dataGrid: GridRowParams) => {
      await handleRecordUserInteractions([
        {
          type: "LEAD_CLICKED",
          lead_id: String(dataGrid.id),
          lens_id: currentLensId,
        },
      ])

      const tempSelectedIds = [...selectedIds]

      if (dataGrid.row.new) {
        const index = selectedIds.indexOf(dataGrid.id as string)

        if (index === -1) {
          tempSelectedIds.push(dataGrid.id as string)
        }

        dispatch(setSelectedIds(tempSelectedIds))
      }

      dispatch(
        toggleNavDrawer({
          isOpen: true,
          partial: NavDrawerState.LEAD_INFOS,
          data: dataGrid,
        }),
      )
    },
    [currentLensId, dispatch, handleRecordUserInteractions, selectedIds],
  )

  const [gridSortModel, setGridSortModel] = useState<GridSortModel>([])

  const onSortChange = useCallback(
    (filterModel: GridSortModel) => {
      try {
        const field = filterModel?.[0]?.field?.toUpperCase()
        const sort = filterModel?.[0]?.sort?.toUpperCase()

        let generatedValue: LeadOrder | undefined

        if (field === "LIKED") {
          generatedValue = sort === "ASC" ? `DISLIKED:DESC` : `LIKED:DESC`
        } else {
          switch (field) {
            case "ID":
              generatedValue = `ID:${sort}` as LeadOrder
              break
            case "NEW":
              generatedValue = `NEW:${sort}` as LeadOrder
              break
            case "NAME":
              generatedValue = `NAME:${sort}` as LeadOrder
              break
            case "SCORE":
              generatedValue = `SCORE:${sort}` as LeadOrder
              break
            case "SECTOR":
              generatedValue = `SECTOR:${sort}` as LeadOrder
              break
            case "SIZE":
              generatedValue = `SIZE:${sort}` as LeadOrder
              break
            case "STATUS":
              generatedValue = `STATUS:${sort}` as LeadOrder
              break
            case "WEBSITE":
              generatedValue = `WEBSITE:${sort}` as LeadOrder
              break
            default:
              generatedValue = undefined
              break
          }
        }

        dispatch(setSortModel(generatedValue ? [generatedValue] : undefined))
      } catch (error) {
        setGridSortModel(filterModel)
        dispatch(setSortModel(undefined))
        console.error(error)
      } finally {
        setGridSortModel(filterModel)
      }
    },
    [wishlistViewMode],
  )

  const { columns } = useWishlistColumns({
    wishlistViewMode,
    showLoader,
  })

  const currentVisibilityModel =
    wishlistViewMode === WishlistViewMode.MONITOR
      ? WISHLIST_MONITOR_VISIBILITY_MODEL
      : WISHLIST_DISCOVER_VISIBILITY_MODEL

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      score: false,
      type: false,
      status: gridSubject === WishlistViewMode.MONITOR,
      status_prediction: wishlistViewMode !== WishlistViewMode.MONITOR,
    })

  useAsyncEffect(async () => {
    const fetchSavedModel = async () => {
      const savedModel = await localforage.getItem<string>(
        currentVisibilityModel,
      )

      setColumnVisibilityModel(
        savedModel
          ? JSON.parse(savedModel)
          : {
              score: false,
              type: false,
              status: gridSubject === WishlistViewMode.MONITOR,
              status_prediction: wishlistViewMode !== WishlistViewMode.MONITOR,
            },
      )
    }

    fetchSavedModel()
  }, [currentVisibilityModel])

  const handleColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(model)

      localforage.setItem(currentVisibilityModel, JSON.stringify(model))
    },
    [currentVisibilityModel],
  )

  if (rows.length === 0)
    return (
      <Box
        sx={{
          ...CENTERED_FLEX_COL,
          width: "100%",
          height: "calc(100vh - 90px)",
        }}
      >
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            height: "100%",
          }}
        >
          {showLoader ? (
            <Box
              sx={{
                ...CENTERED_FLEX_ROW,
                transform: "translateY(-5vh)",
              }}
            >
              <Typography className="animate-pulse">
                Calc leads data...
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                ...CENTERED_FLEX_ROW,
                transform: "translateY(-5vh)",
              }}
            >
              <Typography sx={{ marginLeft: 1 }}>
                No lead found with this filter. Try to modify it.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )

  return (
    <DataGrid
      ref={dataGridRef}
      sortModel={gridSortModel}
      sortingMode="server"
      onSortModelChange={onSortChange}
      keepNonExistentRowsSelected
      onRowClick={handleRowClick}
      onPaginationModelChange={handleSetPaginationModel}
      paginationMode="server"
      rowCount={rowCount ?? rows.length}
      paginationModel={paginationModel}
      disableRowSelectionOnClick
      getRowClassName={() =>
        showLoader ? "animate-pulse lead-row" : "lead-row"
      }
      initialState={{
        pagination: {
          rowCount: rowCount ?? rows.length,
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      slots={{
        toolbar,
      }}
      sx={{
        borderRadius: 0,
        border: 0,
        m: 0,
        backgroundColor: "#fff",
        ".MuiDataGrid-columnHeaderTitle": {
          color: "text.secondary",
        },
        "& .MuiDataGrid-cell[data-field=name]": {
          overflow: "visible",
        },
      }}
      pagination
      hideFooter={hideFooter}
      // @ts-expect-error clipboardPaste is an experimental feature
      experimentalFeatures={{ clipboardPaste: true }}
      unstable_ignoreValueFormatterDuringExport
      disableColumnFilter
      rows={rows}
      columns={columns}
      rowHeight={40}
      getRowSpacing={() => ({
        top: 2,
        bottom: 2,
      })}
    />
  )
}
