import { LbFade } from "@components/animations/LbFade/LbFade"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL, CENTERED_FLEX_COL_START } from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"

import { Box, Typography } from "@mui/material"
import { toast } from "react-toastify"

export const CheckMailScreen = () => {
  const { token } = useAppSelector(selectAuthState)

  const handleResendEmail = async () => {
    try {
      const url = `${
        import.meta.env.VITE_LEADBAY_API_BASE_URL
      }/auth/resend_email`

      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      toast.success("Email confirmation sent successfully")
    } catch (error) {
      toast.error("Error resending email")

      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        ...CENTERED_FLEX_COL_START,
        width: "100vw",
        height: "100vh",
        background:
          "linear-gradient(132.96deg, #CDCED8 8.68%, #DCFFDE 21.7%, #CDCED8 44.44%)",
      }}
    >
      <LbFade>
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            gap: "88px",
            paddingTop: "20vh",
          }}
        >
          <LBLogo />
          <Box
            sx={{
              ...CENTERED_FLEX_COL,
              gap: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "26.06px",
                color: "#5A5C68",
              }}
              component="h2"
            >
              Your account has been created !
            </Typography>
            <Typography
              sx={{
                fontSize: "37px",
                fontWeight: "700",
                lineHeight: "48.21px",
                color: "#000000",
                textAlign: "center",
              }}
              component="h1"
            >
              Check your mail box to activate your account.
            </Typography>
          </Box>

          <LbButton
            onClick={handleResendEmail}
            id="resend-email-confirmation"
            variant="contained"
            color="primary"
            sx={{
              textTransform: "capitalize",
              width: "300px",
              height: "48px",
            }}
          >
            Resend email confirmation
          </LbButton>
        </Box>
      </LbFade>
    </Box>
  )
}
