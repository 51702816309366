import { toast } from "react-toastify"
import { useAppDispatch } from ".."
import {
  leadbayApi,
  useGetLeadsSavedQuery,
  usePostLeadsUnsaveAllMutation,
} from "../../api"

export const useSavedLeads = () => {
  const dispatch = useAppDispatch()

  const { data: savedLeads, isFetching } = useGetLeadsSavedQuery({
    count: 1000,
    page: 0,
  })

  const [unSaveAll, { isLoading: unSaveAllLoading }] =
    usePostLeadsUnsaveAllMutation()

  const handleUnSaveAllLeads = async () => {
    try {
      await unSaveAll()

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (error) {
      console.error(error)

      toast.error("Failed to unsave all leads")
    }
  }

  const getSortedLeadsBySource = (source: string) =>
    savedLeads?.items
      ?.filter((lead) => lead.saved_source === source)
      .sort((a, b) => {
        if (
          a.saved_at &&
          b.saved_at &&
          new Date(a.saved_at) < new Date(b.saved_at)
        )
          return 1
        if (
          a.saved_at &&
          b.saved_at &&
          new Date(a.saved_at) > new Date(b.saved_at)
        )
          return -1
        return 0
      })

  const monitorLeadsData = getSortedLeadsBySource("MONITOR")
  const wishlistLeadsData = getSortedLeadsBySource("DISCOVER")
  const timeLineLeadsData = getSortedLeadsBySource("TIMELINE")

  const countSavedLeads =
    (monitorLeadsData?.length ?? 0) +
    (wishlistLeadsData?.length ?? 0) +
    (timeLineLeadsData?.length ?? 0)

  return {
    countSavedLeads,

    isFetching,

    monitorLeadsData,
    wishlistLeadsData,
    timeLineLeadsData,

    allSavedLeads: savedLeads?.items,

    handleUnSaveAllLeads,
    unSaveAllLoading,
  }
}
