import { LbFade } from "@components/animations/LbFade/LbFade"
import { LbDataGridSearch } from "@components/inputs/LbDataGridSearch/LbDataGridSearch"
import { APP_DRAWER_WIDTH } from "@leadbay/constants"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  NavDrawerState,
  selectCommonsState,
  setCurrentFilter,
  toggleNavDrawer,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { Box } from "@mui/system"
import { ChangeEvent, memo, useCallback, useEffect } from "react"
import { Discover } from "./partials/Discover/Discover"
import { Monitor } from "./partials/Monitor/Monitor"
import { SavedLead } from "./partials/SavedLead/SavedLead"
import { Timeline } from "./partials/Timeline/Timeline"

const componentMap = {
  [WishlistViewMode.TIMELINE]: <Timeline />,
  [WishlistViewMode.SAVED_LEADS]: <SavedLead />,
  [WishlistViewMode.DISCOVER]: <Discover />,
  [WishlistViewMode.MONITOR]: <Monitor />,
}

const WishlistScreen = memo(() => {
  const dispatch = useAppDispatch()

  const { drawerData, wishlistViewMode, currentFilter, navDrawerIsOpen } =
    useAppSelector(selectCommonsState)

  const isMonitorOrWishlist = ![
    WishlistViewMode.TIMELINE,
    WishlistViewMode.SAVED_LEADS,
  ].includes(wishlistViewMode)

  useEffect(() => {
    if (isMonitorOrWishlist) {
      dispatch(
        toggleNavDrawer({
          isOpen: true,
          partial: NavDrawerState.SCORING_PARAMETERS,
          data: drawerData,
        }),
      )
    } else {
      dispatch(
        toggleNavDrawer({
          isOpen: false,
          partial: NavDrawerState.LEAD_INFOS,
        }),
      )
    }
  }, [wishlistViewMode])

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setCurrentFilter(event.target.value))
    },
    [dispatch],
  )

  return (
    <Box
      id="dashboard"
      sx={{
        minHeight: "calc(100vh - 30px)",
        position: "relative",
      }}
    >
      <Box
        component="article"
        sx={{
          minHeight: "calc(100vh - 30px)",
          width: navDrawerIsOpen
            ? `calc(100vw - ${APP_DRAWER_WIDTH})`
            : "100vw",
          px: "20px",
          willChange: "width",
          transition: "all 0.2s ease",
        }}
      >
        <LbDataGridSearch onSearch={handleSearch} searchValue={currentFilter} />

        <LbFade key={wishlistViewMode} duration={0.8}>
          <Box
            sx={{
              height: "100%",
            }}
          >
            {componentMap[wishlistViewMode]}
          </Box>
        </LbFade>
      </Box>
    </Box>
  )
})

export default WishlistScreen
