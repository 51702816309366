import {
  usePostLeadsByLeadIdClearLikeMutation,
  usePostLeadsByLeadIdClearSaveMutation,
  usePostLeadsByLeadIdDislikeMutation,
  usePostLeadsByLeadIdLikeMutation,
  usePostLeadsByLeadIdSaveMutation,
  type FullLead,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import { sourceDict } from "@leadbay/state/hooks/leads/useSelectLeads"
import {
  selectCommonsState,
  setTempLikedLeads,
} from "@leadbay/state/slices/commonsSlice"
import { analytics } from "@leadbay/utils"
import {
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  TurnedIn,
  TurnedInNot,
} from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { toast } from "react-toastify"

export type SavedStatus = "saved" | "unsaved"

export type LikeStatus = "like" | "dislike" | "none"

interface LbFavoriteModuleProps {
  lead: FullLead
}

export const LbFavoriteModule = ({ lead }: LbFavoriteModuleProps) => {
  const { id: leadId, liked, disliked, saved_source } = lead

  const { wishlistViewMode } = useAppSelector(selectCommonsState)

  const dispatch = useAppDispatch()

  const [postLeadsByLeadIdSave] = usePostLeadsByLeadIdSaveMutation()
  const [postLeadsByLeadIdClear] = usePostLeadsByLeadIdClearSaveMutation()
  const [likeMutation] = usePostLeadsByLeadIdLikeMutation()
  const [dislikeMutation] = usePostLeadsByLeadIdDislikeMutation()
  const [clearLikeMutation] = usePostLeadsByLeadIdClearLikeMutation()

  const [likeStatus, setLikeStatus] = useState<LikeStatus>(
    liked ? "like" : disliked ? "dislike" : "none",
  )

  const [saveStatus, setSaveStatus] = useState<SavedStatus>(
    saved_source ? "saved" : "unsaved",
  )

  const handleSetTempLikedLeads = (id: string, liked: LikeStatus) =>
    dispatch(setTempLikedLeads({ id, liked }))

  const handleLikeLead = async (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const previousLikeStatus = likeStatus
    const newLikeStatus = likeStatus === "like" ? "none" : "like"
    setLikeStatus(newLikeStatus)
    handleSetTempLikedLeads(leadId, newLikeStatus)

    try {
      if (newLikeStatus === "like") {
        await likeMutation({ leadId }).unwrap()
        analytics.vote({ voteType: "like" })
      } else {
        await clearLikeMutation({ leadId }).unwrap()
      }
    } catch (error) {
      setLikeStatus(previousLikeStatus)
      handleSetTempLikedLeads(leadId, previousLikeStatus)
      toast.error("An error occurred while liking the lead")
      console.error(error)
    }
  }

  const handleDislikeLead = async (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const previousLikeStatus = likeStatus
    const newLikeStatus = likeStatus === "dislike" ? "none" : "dislike"
    setLikeStatus(newLikeStatus)
    handleSetTempLikedLeads(leadId, newLikeStatus)

    try {
      if (newLikeStatus === "dislike") {
        await dislikeMutation({ leadId }).unwrap()
        analytics.vote({ voteType: "dislike" })
      } else {
        await clearLikeMutation({ leadId }).unwrap()
      }
    } catch (error) {
      setLikeStatus(previousLikeStatus)
      handleSetTempLikedLeads(leadId, previousLikeStatus)
      toast.error("An error occurred while disliking the lead")
      console.error(error)
    }
  }

  const handleSaveLead = async (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const previousSaveStatus = saveStatus
    const newSaveStatus = saveStatus === "saved" ? "unsaved" : "saved"

    setSaveStatus(newSaveStatus)

    const source = sourceDict[wishlistViewMode]

    try {
      if (newSaveStatus === "saved") {
        await postLeadsByLeadIdSave({
          leadId,
          setSavedRequest: { source },
        }).unwrap()
      } else {
        await postLeadsByLeadIdClear({ leadId }).unwrap()
      }
    } catch (error) {
      setSaveStatus(previousSaveStatus)

      toast.error("An error occurred while saving the lead")
      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all 0.3s ease-in-out",
        width: "100%",
        ml: 1,
      }}
    >
      <IconButton
        onClick={handleSaveLead}
        sx={{ width: "40px", height: "40px" }}
      >
        {saveStatus === "saved" ? (
          <TurnedIn color="primary" sx={{ fontSize: "1.2rem" }} />
        ) : (
          <TurnedInNot color="primary" sx={{ fontSize: "1.2rem" }} />
        )}
      </IconButton>

      <Tooltip title="Like leads to receive more tailored recommendations">
        <IconButton
          onClick={handleLikeLead}
          sx={{ width: "40px", height: "40px" }}
        >
          {likeStatus === "like" ? (
            <ThumbUp color="primary" sx={{ fontSize: "1.2rem" }} />
          ) : (
            <ThumbUpOutlined color="primary" sx={{ fontSize: "1.2rem" }} />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Dislike leads to receive more tailored recommendations">
        <IconButton
          onClick={handleDislikeLead}
          sx={{ width: "40px", height: "40px" }}
        >
          {likeStatus === "dislike" ? (
            <ThumbDown color="primary" sx={{ fontSize: "1.2rem" }} />
          ) : (
            <ThumbDownOutlined color="primary" sx={{ fontSize: "1.2rem" }} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  )
}
