import { ExpandMore } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

import { LbExportGrid } from "@components/display/LbExportGrid/LbExportGrid"
import { LbGoogleMapExport } from "@components/display/LbGoogleMapExport/LbGoogleMapExport"
import {
  leadbayApi,
  useGetExportTargetsQuery,
  usePostLeadsByLeadIdClearSaveMutation,
} from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useExportLeads,
  useFeatureFlagCheck,
  useSavedLeads,
} from "@leadbay/state/hooks"
import { FeatureFlagEnum } from "@leadbay/state/hooks/commons/useFeatureFlagCheck"
import {
  selectCommonsState,
  setWishlistViewMode,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { analytics, getAvailableTargets } from "@leadbay/utils"
import { GridRowSelectionModel } from "@mui/x-data-grid"
import clsx from "clsx"

export const SavedLead = () => {
  const dispatch = useAppDispatch()

  const {
    isFetching,
    countSavedLeads,
    monitorLeadsData,
    wishlistLeadsData,
    timeLineLeadsData,
    allSavedLeads,
  } = useSavedLeads()

  const { wishlistViewMode } = useAppSelector(selectCommonsState)

  const { data: targetsList } = useGetExportTargetsQuery()

  const targets = useMemo(() => getAvailableTargets(targetsList), [targetsList])

  const [clearSave] = usePostLeadsByLeadIdClearSaveMutation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [globalSelection, setGlobalSelection] = useState<string[]>([])

  const selectedLeads = useMemo(
    () =>
      allSavedLeads?.filter((lead) =>
        globalSelection.includes(lead.id.toString()),
      ) ?? [],
    [allSavedLeads, globalSelection, countSavedLeads],
  )

  const disableMapExport = useMemo(
    () =>
      selectedLeads.filter((lead) => lead?.location?.pos?.length).length === 0,
    [selectedLeads, countSavedLeads],
  )

  const handleOpenExportMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const isMapExportEnabled = useFeatureFlagCheck(FeatureFlagEnum.MAP_VIEW)
  const [openMap, setOpenMap] = useState(false)

  const handleToggleMap = useCallback(() => {
    setOpenMap((prev) => !prev)
  }, [])

  const [exportLoading, setExportLoading] = useState(false)
  const [currentTarget, setCurrentTarget] = useState<string | undefined>()

  const { handleExportLeads } = useExportLeads({
    onSuccess: (selectedTarget: string, targetName: string) => {
      if (selectedTarget === "f") {
        dispatch(leadbayApi.util.invalidateTags(["Leads"]))

        if (globalSelection.length > 0) {
          toast.success(
            <div>
              <Typography sx={{ mb: 2 }}>
                {globalSelection.length} leads exported.
              </Typography>
            </div>,
          )
        } else if (allSavedLeads) {
          toast.success(
            <div>
              <Typography sx={{ mb: 2 }}>
                {allSavedLeads.length} leads exported.
              </Typography>
            </div>,
          )
        }
      } else {
        const message =
          targetName === "file"
            ? "Export successful"
            : `Export to ${targetName} successful.`

        toast.success(message)
      }
    },
    onError: (err: Error) => {
      toast.error(err.message || "Échec de l'exportation des leads")
    },
    onFinished: (selectedTarget: string) => {
      if (selectedTarget) {
        analytics.finishExportingLeads({
          numLeads: countSavedLeads,
          target: selectedTarget as string,
        })
      }

      setExportLoading(false)
      setCurrentTarget(undefined)
    },
  })

  const [grid1Selection, setGrid1Selection] = useState<GridRowSelectionModel>(
    [],
  )
  const [grid2Selection, setGrid2Selection] = useState<GridRowSelectionModel>(
    [],
  )
  const [grid3Selection, setGrid3Selection] = useState<GridRowSelectionModel>(
    [],
  )

  const handleGrid1Selection = (selected: GridRowSelectionModel) => {
    setGrid1Selection(selected)
  }

  const handleGrid2Selection = (selected: GridRowSelectionModel) => {
    setGrid2Selection(selected)
  }

  const handleGrid3Selection = (selected: GridRowSelectionModel) => {
    setGrid3Selection(selected)
  }

  useEffect(() => {
    const allSelections = Array.from(
      new Set([...grid1Selection, ...grid2Selection, ...grid3Selection]),
    )

    setGlobalSelection(allSelections as string[])
  }, [grid1Selection, grid2Selection, grid3Selection, countSavedLeads])

  const getFirstGridWithData = () => {
    if (wishlistLeadsData?.length) return "wishlist"
    if (monitorLeadsData?.length) return "monitor"
    if (timeLineLeadsData?.length) return "timeline"
    return null
  }

  const firstGridWithData = getFirstGridWithData()

  const handleSelectAllSavedLeads = () => {
    if (globalSelection.length === countSavedLeads) {
      setGrid1Selection([])
      setGrid2Selection([])
      setGrid3Selection([])
      setGlobalSelection([])
    } else {
      setGrid1Selection(
        wishlistLeadsData?.map((lead) => lead.id.toString()) ?? [],
      )
      setGrid2Selection(
        monitorLeadsData?.map((lead) => lead.id.toString()) ?? [],
      )
      setGrid3Selection(
        timeLineLeadsData?.map((lead) => lead.id.toString()) ?? [],
      )
      setGlobalSelection(allSavedLeads?.map((lead) => lead.id.toString()) ?? [])
    }
  }

  const clearSavedLeads = async () => {
    try {
      const promises = globalSelection?.map((lead) =>
        clearSave({ leadId: lead }),
      )

      if (promises) {
        await Promise.all(promises)
      }

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))

      setGrid1Selection((prev) =>
        prev.filter((id) => !globalSelection.includes(id.toString())),
      )
      setGrid2Selection((prev) =>
        prev.filter((id) => !globalSelection.includes(id.toString())),
      )
      setGrid3Selection((prev) =>
        prev.filter((id) => !globalSelection.includes(id.toString())),
      )
      setGlobalSelection((prev) =>
        prev.filter((id) => !globalSelection.includes(id.toString())),
      )
    } catch (error: unknown) {
      toast.error("An error occurred while unsaving all leads")
      console.error(error)
    }
  }

  useEffect(() => {
    if (countSavedLeads === 0) {
      dispatch(setWishlistViewMode(WishlistViewMode.DISCOVER))
    }
  }, [countSavedLeads])

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          position: "sticky",
          top: "95.5px",
          zIndex: 999,
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          mb: 1,
          width: "100%",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Box
          sx={{
            ml: 0.5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={handleSelectAllSavedLeads}
              checked={globalSelection.length === countSavedLeads}
            />

            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                fontWeight: "bold",
              }}
            >
              {globalSelection.length === countSavedLeads
                ? "Unselect all"
                : "Select all"}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <ToggleButtonGroup value="" size="small">
            <ToggleButton
              disabled={countSavedLeads === 0 || globalSelection.length === 0}
              color="primary"
              value={WishlistViewMode.DISCOVER}
              sx={{
                textTransform: "capitalize",
                height: "100%",
                backgroundColor: "white",
              }}
              onClick={clearSavedLeads}
            >
              <Typography
                sx={{
                  mx: 1,
                }}
                variant="subtitle2"
                fontWeight="bold"
              >
                Unsave
                <span>
                  {globalSelection.length > 0
                    ? ` (${globalSelection.length})`
                    : ""}
                </span>
              </Typography>
            </ToggleButton>

            <Box display="flex">
              <ToggleButton
                disabled={countSavedLeads === 0 || globalSelection.length === 0}
                value={WishlistViewMode.DISCOVER}
                sx={{
                  textTransform: "capitalize",
                  height: "100%",
                  pr: 2,
                  backgroundColor: "white",
                }}
                onClick={handleOpenExportMenu}
              >
                <ExpandMore
                  sx={{
                    fontSize: 17,
                  }}
                />
                Export{" "}
                <span>
                  {globalSelection.length > 0
                    ? `(${globalSelection.length})`
                    : ""}
                </span>
              </ToggleButton>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {targets?.targets?.map((target) => (
                  <MenuItem
                    key={target.id}
                    onClick={async () => {
                      setExportLoading(true)
                      setCurrentTarget(target.id)

                      await handleExportLeads({
                        wishlistViewMode,
                        selectedTarget: target.id,
                        selectedLeads: globalSelection,
                        connector: target.connector,
                      })
                    }}
                    className={clsx(
                      target.id === currentTarget &&
                        exportLoading &&
                        "animate-pulse",
                    )}
                  >
                    <Typography
                      sx={{ mx: 1 }}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      {target.connector?.name ?? "CSV"}
                    </Typography>

                    {target.id === currentTarget && exportLoading && (
                      <CircularProgress size={15} />
                    )}
                  </MenuItem>
                ))}

                {isMapExportEnabled && (
                  <MenuItem
                    disabled={disableMapExport || globalSelection.length === 0}
                    onClick={handleToggleMap}
                  >
                    <Typography
                      sx={{ mx: 1 }}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      Map
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </ToggleButtonGroup>

          <LbGoogleMapExport
            open={openMap}
            onClose={handleToggleMap}
            selectedLeadsData={selectedLeads}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "calc(100vw - 500px)",
        }}
      >
        {(wishlistLeadsData?.length ?? 0) > 0 && (
          <LbExportGrid
            isFirstGridThatHaveData={firstGridWithData === "wishlist"}
            rowSelectionModel={grid1Selection}
            selectedLeads={wishlistLeadsData}
            showLoader={isFetching}
            title="Discover"
            onSelectionChange={handleGrid1Selection}
            savedLeads
          />
        )}

        {(monitorLeadsData?.length ?? 0) > 0 && (
          <LbExportGrid
            isFirstGridThatHaveData={firstGridWithData === "monitor"}
            rowSelectionModel={grid2Selection}
            selectedLeads={monitorLeadsData}
            showLoader={isFetching}
            title="Monitor"
            onSelectionChange={handleGrid2Selection}
            savedLeads
          />
        )}

        {(timeLineLeadsData?.length ?? 0) > 0 && (
          <LbExportGrid
            isFirstGridThatHaveData={firstGridWithData === "timeline"}
            rowSelectionModel={grid3Selection}
            selectedLeads={timeLineLeadsData}
            showLoader={isFetching}
            title="Timeline"
            onSelectionChange={handleGrid3Selection}
            savedLeads
          />
        )}
      </Box>
    </Box>
  )
}
