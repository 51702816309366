import React from "react"
import { Box, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL, CENTERED_FLEX_ROW } from "@leadbay/constants"

interface LbSecondaryActionProps {
  route: string
  buttonId: string
  buttonLabel: string
}

const LbSecondaryAction: React.FC<LbSecondaryActionProps> = ({
  route,
  buttonId,
  buttonLabel,
}) => {
  return (
    <Box sx={CENTERED_FLEX_COL} width="100%" gap={1}>
      <Box sx={CENTERED_FLEX_ROW} width="100%">
        <Box
          borderBottom="1px solid lightgray"
          height="1px"
          margin={1}
          sx={{ width: "80px" }}
        />
        <Typography color="textSecondary">or</Typography>
        <Box
          sx={{ width: "80px" }}
          borderBottom="1px solid lightgray"
          height="1px"
          margin={1}
        />
      </Box>

      <LbButton
        id="register-button"
        variant="outlined"
        color="primary"
        component={RouterLink}
        to={route}
        sx={{
          textTransform: "capitalize",
          width: "100%",
          height: "48px",
        }}
      >
        {buttonLabel}
      </LbButton>
    </Box>
  )
}

export default LbSecondaryAction
