import { MAPPING_STEP } from "@leadbay/constants"
import localforage from "localforage"
import { useState } from "react"
import { usePostImportsMutation } from "../../api"
import { setSelectedFile } from "../../slices/onboardingSlice"
import { useAppDispatch } from "../commons/useAppDispatch"

export const useUpload = () => {
  const dispatch = useAppDispatch()

  const [uploadError, setUploadError] = useState<string | undefined>()
  const [launchUpload, setLaunchUpload] = useState<boolean>(false)

  const [crmUploadFile] = usePostImportsMutation()

  const handleFileUpload = async (selectedFile: File) => {
    try {
      setLaunchUpload(true)

      await localforage.removeItem(MAPPING_STEP)

      setUploadError(undefined)

      dispatch(setSelectedFile(selectedFile))

      const response = await crmUploadFile({
        fileName: selectedFile.name,
        body: selectedFile,
      })

      if ("error" in response)
        throw new Error("Something went wrong, please try again later")
    } catch (error) {
      const err = error as Error

      setUploadError(err.message)
    }
  }

  return {
    setUploadError,
    handleFileUpload,
    uploadError,
    launchUpload,
  }
}
