import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { useWebAppLogout } from "@hooks/useWebAppLogout"
import { WEB_APP_ROUTES } from "@leadbay/constants"
import { useGetStripePortalQuery } from "@leadbay/state/api"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import {
  AccountBalance,
  AccountBalanceWallet,
  Apps,
  CreditCardOutlined,
  Delete,
  Home,
  Logout,
  Mail,
  MenuBook,
  Menu as MenuIcon,
  People,
  ThumbUp,
} from "@mui/icons-material"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"
import { useAsyncEffect } from "ahooks"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { LbInstalButton } from "./partials/LbInstalButton"

export const LbMainNav = () => {
  const { logout } = useWebAppLogout()

  const [menuOpen, setMenuOpen] = useState(false)
  const [subscriptionClicked, setSubscriptionClicked] = useState(false)

  const { user, subscriptionRequired } = useAppSelector(selectAuthState)

  const { data: billingUrl, refetch } = useGetStripePortalQuery(undefined, {
    skip: !subscriptionClicked,
  })

  const handleOpenStripePortal = () => {
    setSubscriptionClicked(true)
  }

  useEffect(() => {
    if (billingUrl) {
      window.open(billingUrl.url)

      setSubscriptionClicked(false)
    }
  }, [billingUrl])

  useAsyncEffect(async () => {
    if (menuOpen && billingUrl) await refetch()
  }, [menuOpen])

  const location = useLocation()

  const handleLogout = async () => {
    const confirm = window.confirm("Are you sure you want to logout?")

    if (confirm) await logout()
  }

  const handleToggleMenu = (open: boolean) => setMenuOpen(open)

  return (
    <Box>
      {!subscriptionRequired && (
        <>
          <IconButton
            id="menu-button"
            onClick={() => handleToggleMenu(true)}
            disableRipple
            sx={{ mr: 1, zIndex: 10, color: "black" }}
          >
            <Tooltip title="Menu" placement="bottom">
              <MenuIcon />
            </Tooltip>
          </IconButton>

          <Drawer
            open={menuOpen}
            onClose={() => handleToggleMenu(false)}
            elevation={2}
            BackdropProps={{
              sx: {
                background: "rgba(255,255,255,0.8)",
              },
            }}
            sx={{
              border: "1px solid black",
            }}
          >
            <List
              sx={{
                maxWidth: "100%",
                height: "100vh",
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            >
              <Link
                to={user ? WEB_APP_ROUTES.APP : WEB_APP_ROUTES.ROOT}
                onClick={() => handleToggleMenu(false)}
              >
                <Box sx={{ px: 2, mt: 1.5, mb: 3 }}>
                  <LBLogo />
                </Box>
              </Link>

              <Link
                to={WEB_APP_ROUTES.APP}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={location.pathname === WEB_APP_ROUTES.APP}
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <Home />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Wishlist
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link
                to={WEB_APP_ROUTES.PRIVATE.FAVORITES}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname === WEB_APP_ROUTES.PRIVATE.FAVORITES
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <ThumbUp
                        sx={{
                          fontSize: "1.3rem",
                        }}
                      />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Liked Leads
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link
                to={WEB_APP_ROUTES.PRIVATE.TRASH}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname ===
                      WEB_APP_ROUTES.APP + WEB_APP_ROUTES.PRIVATE.TRASH
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <Delete />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Trash
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link
                to={WEB_APP_ROUTES.PRIVATE.INTEGRATIONS}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname ===
                      WEB_APP_ROUTES.APP + WEB_APP_ROUTES.PRIVATE.INTEGRATIONS
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <Apps />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Data
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Divider sx={{ my: 1.5 }} />

              <Link
                to={WEB_APP_ROUTES.PRIVATE.ACCOUNT}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname ===
                      WEB_APP_ROUTES.APP + WEB_APP_ROUTES.PRIVATE.ACCOUNT
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <People />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Account
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link
                to={WEB_APP_ROUTES.PRIVATE.ORGANIZATION}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname ===
                      WEB_APP_ROUTES.APP + WEB_APP_ROUTES.PRIVATE.ORGANIZATION
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <AccountBalance />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        My organization
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <ListItem dense disablePadding>
                <ListItemButton onClick={handleOpenStripePortal}>
                  <ListItemIcon sx={{ color: "inherit" }}>
                    <AccountBalanceWallet />
                  </ListItemIcon>

                  <ListItemText>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      fontWeight={900}
                    >
                      Subscription
                    </Typography>
                  </ListItemText>

                  {(user?.organization?.billing.suspended ??
                  user?.organization?.billing.status === "ACTION_NEEDED") ? (
                    <Chip
                      label="Action needed"
                      size="small"
                      sx={{ fontWeight: "bold" }}
                    />
                  ) : (
                    user?.organization?.billing.status !== "OK" && (
                      <Chip
                        label="Not enabled"
                        size="small"
                        sx={{ fontWeight: "bold" }}
                      />
                    )
                  )}
                </ListItemButton>
              </ListItem>

              {user?.organization?.billing.status === "ACTION_NEEDED" &&
                billingUrl?.url && (
                  <a
                    href={billingUrl.url}
                    rel="noopenner noreferrer"
                    onClick={() => handleToggleMenu(false)}
                  >
                    <ListItem dense disablePadding>
                      <ListItemButton>
                        <ListItemIcon sx={{ color: "inherit" }}>
                          <CreditCardOutlined
                            fontSize="small"
                            color="primary"
                          />
                        </ListItemIcon>

                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            component="span"
                            fontWeight={900}
                          >
                            Manage billing
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </a>
                )}

              <Divider sx={{ my: 1.5 }} />

              <a
                href="https://docs.leadbay.app/leadbay-user-docs/"
                target="_blank"
                rel="noopenner noreferrer"
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <MenuBook />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Documentation
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </a>

              {/*
              <Link
                to={WEB_APP_ROUTES.PUBLIC.CHANGELOG_SCREEN}
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton
                    selected={
                      location.pathname === WEB_APP_ROUTES.APP +WEB_APP_ROUTES.PUBLIC.CHANGELOG_SCREEN
                    }
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <Star />
                    </ListItemIcon>

                    <ListItemText>
                      <Badge
                        color="primary"
                        badgeContent="1"
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="span"
                          fontWeight={900}
                        >
                          Release
                        </Typography>
                      </Badge>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
              */}

              <a
                href="mailto:help@leadbay.ai"
                rel="noopenner"
                onClick={() => handleToggleMenu(false)}
              >
                <ListItem dense disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <Mail />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        fontWeight={900}
                      >
                        Help desk
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </a>

              <LbInstalButton onToggle={handleToggleMenu} />

              <Divider sx={{ my: 1.5 }} />

              <ListItem
                dense
                disablePadding
                onClick={() => handleToggleMenu(false)}
              >
                <ListItemButton onClick={handleLogout} id="logout-button">
                  <ListItemIcon sx={{ color: "inherit" }}>
                    <Logout />
                  </ListItemIcon>

                  <ListItemText>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      fontWeight={900}
                    >
                      Logout
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <Box sx={{ flex: 1 }} />

              <Card elevation={0} sx={{ background: "transparent" }}>
                <CardHeader
                  sx={{ pb: 3 }}
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: "black",
                        fontFamily: "BasementGrotesque",
                        fontSize: 11,
                      }}
                    >
                      {user?.name[0].toUpperCase()}
                    </Avatar>
                  }
                  title={
                    (user?.email?.length ?? 0) > 27
                      ? `${user?.email.substring(0, 27)}...`
                      : user?.email
                  }
                  subheader={user?.name}
                />
              </Card>

              <Typography
                variant="caption"
                sx={{ mx: "auto", color: "text.secondary" }}
              >
                © {new Date().getFullYear()} Leadbay.ai
              </Typography>
            </List>
          </Drawer>
        </>
      )}
    </Box>
  )
}
