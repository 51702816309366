import { LbWishlistGrid } from "@components/display/LbWishlistGrid/LbWishlistGrid"
import { LbCloseDrawer } from "@components/inputs/LbCloseDrawer/LbCloseDrawer"
import {
  useAppSelector,
  useKeyboardController,
  useWishlistData,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { generateWishlistRows } from "@leadbay/utils"
import { Box } from "@mui/system"
import { useEffect, useMemo, useState } from "react"

export const Monitor = () => {
  const { wishlistViewMode, pageSize } = useAppSelector(selectCommonsState)

  const {
    monitorData,
    checkMonitorComputing,
    isWishlistFetching: isFetching,
    monitorPaginationModel,
    setMonitorPaginationModel,
  } = useWishlistData({
    status: undefined,
  })

  const [rowCountState, setRowCountState] = useState(0)

  const rows = useMemo(
    () => generateWishlistRows(monitorData?.items ?? []),
    [monitorData],
  )

  const { dataGridRef } = useKeyboardController({
    page: monitorPaginationModel.page,
    rows,
  })

  useEffect(() => {
    if (pageSize) {
      setMonitorPaginationModel({
        pageSize,
        page: 0,
      })
    }
  }, [wishlistViewMode, pageSize])

  useEffect(() => {
    const total = monitorData?.pagination.total

    if (total !== undefined) {
      setRowCountState(total)
    }
  }, [monitorData, wishlistViewMode])

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <LbWishlistGrid
        dataGridRef={dataGridRef}
        rows={rows}
        rowCount={rowCountState}
        isFetching={isFetching}
        showLoader={checkMonitorComputing}
        gridSubject={WishlistViewMode.MONITOR}
        paginationModel={monitorPaginationModel}
        setPaginationModel={setMonitorPaginationModel}
      />

      <LbCloseDrawer />
    </Box>
  )
}
