import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import {
  CENTERED_FLEX_COL,
  RESET_PASSWORD_EMAIL,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import {
  usePostAuthLoginMutation,
  usePostAuthPasswordResetChangePasswordMutation,
  type PostAuthLoginApiArg,
  type PostAuthPasswordResetChangePasswordApiArg,
} from "@leadbay/state/api"
import { useAppDispatch, useQuery } from "@leadbay/state/hooks"
import { setToken } from "@leadbay/state/slices/authSlice"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { type LoginScreenValues } from "@screens/auth/LoginScreen/LoginScreenOld"
import localforage from "localforage"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { object, string, type InferType } from "yup"
import LbSecondaryAction from "@components/feedback/LbSecondaryAction/LbSecondaryAction"

const validationSchema = object().shape({
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
  passwordConfirmation: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
})

export type ResetPasswordScreenValues = InferType<typeof validationSchema>

const ResetPasswordScreen = () => {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [
    resetPasswordChange,
    {
      isLoading: resetPasswordChangeIsLoading,
      isSuccess: resetPasswordChangIsSuccess,
      error: resetPasswordChangeError,
    },
  ] = usePostAuthPasswordResetChangePasswordMutation()

  const [
    authLogin,
    {
      data: loginData,
      isLoading: loginIsLoading,
      isSuccess: loginIsSuccess,
      error: loginError,
    },
  ] = usePostAuthLoginMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ResetPasswordScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  })

  const handleLoginUser = async (data: LoginScreenValues) => {
    try {
      const payload: PostAuthLoginApiArg = {
        userLogin: {
          email: data.email,
          password: data.password,
        },
      }

      const result = await authLogin(payload).unwrap()

      navigate(WEB_APP_ROUTES.ROOT)
      dispatch(setToken(result.token))
    } catch (error) {
      console.log(error)
    }
  }

  const handleFormSubmit: SubmitHandler<ResetPasswordScreenValues> = async ({
    passwordConfirmation,
  }) => {
    try {
      if (!passwordConfirmation) throw new Error("Password is required")

      const token = query.get("token")

      if (!token) throw new Error("Token is required")

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      const payload: PostAuthPasswordResetChangePasswordApiArg = {
        passwordReset: {
          new: passwordConfirmation,
        },
      }

      await resetPasswordChange({
        // @ts-expect-error:next-line
        payload,
        headers,
      })

      const email = await localforage.getItem(RESET_PASSWORD_EMAIL)

      if (!email) throw new Error("Email is required")

      await handleLoginUser({
        email: email as string,
        password: passwordConfirmation,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetAuthState = async () => {
    try {
      const token = loginData?.token

      if (!token) {
        console.error("Token is required")

        return
      }

      await localforage.removeItem(RESET_PASSWORD_EMAIL)

      dispatch(setToken(loginData.token))
      navigate(WEB_APP_ROUTES.ROOT)
    } catch (error) {
      console.log(error)
    }
  }

  useApiRequestFeedback({
    isSuccess: resetPasswordChangIsSuccess,
    error: resetPasswordChangeError,
    config: {
      onSuccess: {
        message: {
          text: "Your password has been reset successfully",
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
        401: {
          message: {
            text: "You are not authorized to perform this action",
          },
        },
      },
    },
  })

  useApiRequestFeedback({
    isSuccess: loginIsSuccess,
    error: loginError,
    config: {
      onSuccess: {
        message: {
          text: "You have been logged in successfully",
        },
        action: handleSetAuthState,
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
        401: {
          message: {
            text: "Invalid email or password",
          },
        },
      },
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="reset-password-screen">
      <Box
        sx={{
          ...CENTERED_FLEX_COL,
          rowGap: "88px",
          width: "300px",
        }}
      >
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            rowGap: "40px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              mt: 1,
              fontWeight: "700",
              fontSize: "21px",
              lineHeight: "27.36px",
            }}
            variant="h6"
            component="h2"
          >
            Reset password.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              rowGap: "8px",
            }}
          >
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <LbPasswordField
                  label="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  placeholder="********"
                  fullWidth
                  required
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <LbPasswordField
                  label="Confirm password"
                  placeholder="********"
                  fullWidth
                  required
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <LbButton
            variant="contained"
            color="primary"
            disabled={
              !isValid || resetPasswordChangeIsLoading || loginIsLoading
            }
            type="submit"
            sx={{
              textTransform: "capitalize",
              height: "48px",
              width: "100%",
              "&:hover": {
                backgroundColor: "#191919",
              },
            }}
            loading={resetPasswordChangeIsLoading || loginIsLoading}
          >
            Reset password
          </LbButton>
        </Box>

        <LbSecondaryAction
          route={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.LOGIN}
          buttonId="login-button"
          buttonLabel="Log in"
        />
      </Box>
    </form>
  )
}

export default ResetPasswordScreen
