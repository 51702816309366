import { LbButton } from "@components/feedback/LbButton/LbButton"
import LbSecondaryAction from "@components/feedback/LbSecondaryAction/LbSecondaryAction"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { yupResolver } from "@hookform/resolvers/yup"
import { CENTERED_FLEX_COL_START, WEB_APP_ROUTES } from "@leadbay/constants"
import { useLogin } from "@leadbay/state/hooks"
import { TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import { object, string } from "yup"

export interface LoginScreenValues {
  email: string
  password: string
}

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
  password: string().required().min(1),
})

const LoginScreen = () => {
  const { login, isLoading } = useLogin()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const handleFormSubmit: SubmitHandler<LoginScreenValues> = async (data) => {
    await login({
      email: data.email,
      password: data.password,
    })
  }
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="login-screen">
      <Box
        sx={{
          ...CENTERED_FLEX_COL_START,
          height: "fit-content",
          width: "300px",
          rowGap: "88px",
        }}
      >
        <Box
          sx={{
            ...CENTERED_FLEX_COL_START,
            rowGap: "24px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              mt: 1,
              fontWeight: "700",
              fontSize: "21px",
              lineHeight: "27.36px",
            }}
            variant="h6"
            component="h2"
          >
            Log in to continue.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
              width: "100%",
            }}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                    "& .MuiInputBase-input": {
                      height: "48px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderWidth: "1px",
                      },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <LbPasswordField
                  label="Password"
                  placeholder="********"
                  fullWidth
                  required
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
              width: "100%",
            }}
          >
            <LbButton
              id="login-button"
              variant="contained"
              color="primary"
              size="large"
              disabled={!isValid || isLoading}
              type="submit"
              loading={isLoading}
              sx={{
                textTransform: "capitalize",
                height: "48px",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#191919",
                },
              }}
            >
              Log in
            </LbButton>

            <LbButton
              variant="text"
              color="primary"
              component={RouterLink}
              to={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.FORGOT_PASSWORD}
              sx={{
                textTransform: "capitalize",
                padding: 0,
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: "transparent",
                  textShadow: "0px 0px 3px #21212120",
                },
              }}
            >
              Forgot password ?
            </LbButton>
          </Box>
        </Box>
        <LbSecondaryAction
          route={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.INVITE_REGISTER}
          buttonId="register-button"
          buttonLabel="Create account"
        />
      </Box>
    </form>
  )
}

export default LoginScreen
