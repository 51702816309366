import { differenceInDays, format, parseISO } from "date-fns"
import { FilterCriterion, LeadSimplifiedSize } from "../../state/api"

export const getHintForKey = (
  key: string,
  statusFieldHints?: Record<string, string>,
): string | undefined => {
  if (!statusFieldHints) return

  const entry = Object.entries(statusFieldHints).find(
    ([, hint]) => hint === key,
  )

  return entry ? entry[0] : undefined
}

export const getFilterCriterionData = (criterion: FilterCriterion) => {
  switch (criterion.type) {
    case "size":
      return criterion.sizes.map((sizeItem) => ({
        label: `${sizeItem.min} - ${sizeItem.max}`,
        value: `${sizeItem.min}|${sizeItem.max}`,
      }))
    case "keywords":
      return criterion.keywords.map((keyword) => ({
        label: keyword,
        value: keyword,
      }))
    case "sectors":
      return criterion.sectors.map((sector) => ({
        label: sector,
        value: sector,
      }))
    default:
      console.error("Unknown criterion type", criterion)
  }
}

export const reconstructCriteria = (
  chipsState: Record<string, boolean>,
  originalData: { criteria: FilterCriterion[] }[],
) => {
  const criteria: FilterCriterion[] = []
  const sizeAccumulator: LeadSimplifiedSize[] = []

  originalData.forEach((dataItem) => {
    dataItem.criteria.forEach((criterion: FilterCriterion) => {
      switch (criterion.type) {
        case "size":
          criterion.sizes.forEach((sizeItem) => {
            const sizeLabel = sizeItem.max
              ? `${sizeItem.min} - ${sizeItem.max}`
              : `${sizeItem.min}`

            const sizeValue = chipsState[sizeLabel]

            if (sizeValue) {
              sizeAccumulator.push({
                min: sizeItem.min,
                max: sizeItem.max,
              })
            }
          })
          break

        case "keywords": {
          const activeKeywords = criterion.keywords.filter(
            (keyword: string) => chipsState[keyword],
          )
          if (activeKeywords.length) {
            criteria.push({
              type: "keywords",
              keywords: activeKeywords,
            })
          }
          break
        }

        case "sectors": {
          const activeSectors = criterion.sectors.filter(
            (sector: string) => chipsState[sector],
          )
          if (activeSectors.length) {
            criteria.push({
              type: "sectors",
              sectors: activeSectors,
            })
          }
          break
        }

        default:
          console.error("Unknown criterion type", criterion)
      }
    })
  })

  if (sizeAccumulator.length) {
    criteria.push({
      type: "size",
      sizes: sizeAccumulator,
    })
  }

  return {
    items: [
      {
        criteria,
      },
    ],
  }
}

export const upperCaseFirstLetter = (str: string) => {
  return (str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase())
    .split("_")
    .join(" ")
}

export const countFilterCriterionItems = (
  icpCriteria?: FilterCriterion[],
): number => {
  let itemCount = 0

  if (!icpCriteria) {
    return itemCount
  }

  icpCriteria.forEach((criterion) => {
    switch (criterion.type) {
      case "size":
        itemCount += criterion.sizes.length
        break
      case "keywords":
        itemCount += criterion.keywords.length
        break
      case "sectors":
        itemCount += criterion.sectors.length
        break
      case "location":
        itemCount += criterion.locations.length
        break
      default:
        break
    }
  })

  return itemCount
}

export const removeTrailingS = (str: string) =>
  str.endsWith("s") ? str.slice(0, -1) : str

export const daysUntil = (dateStr?: string) => {
  if (!dateStr) return 0

  const targetDate = new Date(dateStr)
  const today = new Date()

  return differenceInDays(targetDate, today)
}

export const formatUnderCase = (str: string) =>
  str.split(" ").join("_").toLocaleLowerCase()

export const formatDateSmall = (dateStr: string) => {
  const date = parseISO(dateStr)

  return format(date, "dd.MM.yyyy")
}
