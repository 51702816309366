import React from "react"
import { Box, Typography, Chip } from "@mui/material"

interface SectorInfoProps {
  label: string
  tag: string
}

export const LbLeadInfosTags: React.FC<SectorInfoProps> = ({ tag, label }) => {
  return (
    <Box>
      <Typography
        fontWeight={900}
        variant="subtitle1"
        fontFamily="Hanken Grotesk"
        color="text.secondary"
        sx={{ mb: 1 }}
      >
        {label}
      </Typography>
      <Chip label={tag} sx={{ fontWeight: 700 }} />
    </Box>
  )
}
