import { SelectChangeEvent } from "@mui/material"
import {
  useGetLensesQuery,
  usePostLensesByLensIdUpdateLastRequestedMutation,
} from "../../api"
import { selectAuthState } from "../../slices/authSlice"
import { selectCommonsState, setCurrentLensId } from "../../slices/commonsSlice"
import { useAppDispatch, useAppSelector } from "../index"

export const useManageLenses = () => {
  const dispatch = useAppDispatch()

  const { token } = useAppSelector(selectAuthState)

  const [updateLastRequested] =
    usePostLensesByLensIdUpdateLastRequestedMutation()

  const { data: lenses } = useGetLensesQuery(undefined, {
    skip: !token,
  })

  const { currentLensId } = useAppSelector(selectCommonsState)

  const handleChangeLens = async (event: SelectChangeEvent) => {
    if (event.target.value) {
      dispatch(setCurrentLensId(event.target.value))

      await updateLastRequested({ lensId: event.target.value })
    }
  }

  const userLenses = lenses
    ? [...lenses].sort((a) => (a.is_last_active ? 1 : -1))
    : []

  return { userLenses, currentLensId, handleChangeLens }
}
