import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { NewableLead } from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  NavDrawerState,
  selectCommonsState,
  toggleNavDrawer,
} from "@leadbay/state/slices/commonsSlice"
import { generateWishlistRows } from "@leadbay/utils"
import { TransformedEntry } from "@leadbay/utils/commons/generateWishListRows"
import { CloudDownload } from "@mui/icons-material"
import { Box, Tooltip, Typography } from "@mui/material"
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid"
import { formatDate } from "date-fns"
import { useCallback, useEffect, useMemo } from "react"
import { LbDatableCell } from "../LbDatatableCell/LbDatatableCell"

export interface LbExportGridProps {
  selectedLeads?: NewableLead[]
  showLoader: boolean
  title: string
  isFirstGridThatHaveData: boolean
  rowSelectionModel: GridRowSelectionModel
  onSelectionChange: (selectedLeads: GridRowSelectionModel) => void
  savedLeads: boolean
}

export const LbExportGrid = ({
  selectedLeads,
  title,
  rowSelectionModel,
  isFirstGridThatHaveData,
  onSelectionChange,
  savedLeads,
}: LbExportGridProps) => {
  const dispatch = useAppDispatch()

  const { drawerData } = useAppSelector(selectCommonsState)

  const timelineApiRef = useGridApiRef()

  const rows = useMemo(() => {
    const leads = selectedLeads
      ? selectedLeads.filter((lead) => lead.saved_source)
      : []

    return generateWishlistRows(leads)
  }, [selectedLeads])

  const columns: ReadonlyArray<
    GridColDef<
      TransformedEntry & {
        new: boolean
      }
    >
  > = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      description: "The name of the lead",
      flex: 1,
      renderCell: (params) => {
        const row = params.row as unknown as NewableLead

        const exportTooltipTitle =
          savedLeads && row?.saved_at
            ? `Saved at ${formatDate(row.saved_at, "y-m-d '-' H:mm:ss")}`
            : row?.origin?.type !== "EXPORTED"
              ? undefined
              : `Exported from whishlist on ${formatDate(row.origin.date, "y-m-d 'at' H:mm:ss")}`

        return (
          <Tooltip
            title={exportTooltipTitle}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <Box
              width="100%"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  ...CENTERED_FLEX_COL,
                  height: "20px",
                }}
              >
                {row.logo && (
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      marginRight: "8px",
                      borderRadius: "5px",
                    }}
                    src={row.logo}
                    alt={params.value}
                  />
                )}
              </Box>

              <Typography
                variant="subtitle2"
                overflow="hidden"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontFamily: "Hanken Grotesk",
                  pr: 1,
                }}
              >
                {params.value}
              </Typography>

              {row.origin?.type === "EXPORTED" && (
                <>
                  <Box sx={{ width: "8px" }} />

                  <CloudDownload
                    sx={{
                      fontSize: "1rem",
                    }}
                  />
                </>
              )}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      field: "sector",
      flex: 1,
      headerName: "Sector",
      description: "The sector of the lead",
      sortable: true,
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SECTOR"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "location",
      flex: 1,
      headerName: "Location",
      description: "The location of the lead",
      sortable: false,
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="LOCATION"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "size",
      headerName: "Size",
      description: "The size of the lead",
      minWidth: 70,
      sortable: true,
      sortComparator: (v1, v2, param1, param2) => {
        return param2.value?.[1] - param1.value?.[1]
      },
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SIZE"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
  ]

  const handleRowClick = useCallback(async (dataGrid: GridRowParams) => {
    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: NavDrawerState.LEAD_INFOS,
        data: dataGrid,
      }),
    )
  }, [])

  const handleLocalSelectionChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      onSelectionChange(selectionModel)
    },
    [rows],
  )

  useEffect(() => {
    if (!timelineApiRef.current || !isFirstGridThatHaveData) return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: NavDrawerState.LEAD_INFOS,
        data: {
          id: rows[0]?.id,
          row: timelineApiRef?.current?.getRow(rows[0]?.id),
          columns: timelineApiRef?.current?.getAllColumns(),
        },
      }),
    )
  }, [timelineApiRef.current, isFirstGridThatHaveData])

  return (
    <Box>
      <Typography variant="subtitle2" fontWeight="bold">
        {title} {rows.length > 0 && `(${rows.length})`}
      </Typography>

      <DataGrid
        apiRef={timelineApiRef}
        keepNonExistentRowsSelected
        rowCount={rows.length}
        paginationMode="client"
        disableRowSelectionOnClick
        checkboxSelection
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={handleLocalSelectionChange}
        onRowClick={handleRowClick}
        getRowClassName={(params) =>
          params.row.id === drawerData?.id ? "selected-saved-row" : ""
        }
        sx={{
          opacity: !rows.length ? 0 : 1,
          position: !rows.length ? "absolute" : "relative",
          pointerEvents: !rows.length ? "none" : "auto",
          borderRadius: 0,
          border: 0,
          m: 0,
          backgroundColor: "#fff",
          ".MuiDataGrid-columnHeaderTitle": {
            color: "text.secondary",
          },
          "& .MuiDataGrid-cell[data-field=name]": {
            overflow: "visible",
          },
        }}
        pagination
        // @ts-expect-error clipboardPaste is an experimental feature
        experimentalFeatures={{ clipboardPaste: true }}
        unstable_ignoreValueFormatterDuringExport
        disableColumnFilter
        rowHeight={40}
        getRowSpacing={() => ({
          top: 2,
          bottom: 2,
        })}
        rows={rows}
        columns={columns}
      />
    </Box>
  )
}
