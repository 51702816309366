import { LbFade } from "@components/animations/LbFade/LbFade"
import { type HighlightedFields } from "@components/display/LbDatatableCell/LbDatatableCell"
import { WEB_APP_ROUTES } from "@leadbay/constants"
import {
  leadbayApi,
  useGetLensesByLensIdLeadsAndLeadIdPredictStatusQuery,
  useGetLensesByLensIdLeadsAndLeadIdQuery,
  useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery,
  type Lead1,
} from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useRecordUserInteractions,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  setDrawerData,
  setLeadHistory,
  toggleNavDrawer,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"

import { formatDateSmall } from "@leadbay/utils"
import { Box, Typography } from "@mui/material"
import { type GridRowParams } from "@mui/x-data-grid"
import { useAsyncEffect } from "ahooks"
import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { LbLeadInfosContact } from "./subComponents/LbLeadInfosContact"
import LbLeadInfosHeader from "./subComponents/LbLeadInfosHeader"
import { LbLeadInfosItems } from "./subComponents/LbLeadInfosItems"
import { LbLeadInfosKeywords } from "./subComponents/LbLeadInfosKeywords"
import { LbLeadInfosTags } from "./subComponents/LbLeadInfosSector"
import { LbLeadInfosSimilar } from "./subComponents/LbLeadInfosSimilar"
import { LbLeadInfosSocial } from "./subComponents/LbLeadInfosSocial"
import { LbLeadInfosStatus } from "./subComponents/LbLeadInfosStatus"

interface LbLeadData {
  databaseId: string
  keywords: Array<{ label: string; highlighted: boolean; importance: number }>
  location: string
  name: string
  score: number
  website: string
  sector: string
  size: [number, number]
  state: { status: "WON" | "LOST" }
  description: string
  logo: string
  highlighted_fields: HighlightedFields[]
  description_source: string
  social_urls: {
    crunchbase?: string
    facebook?: string
    instagram?: string
    linkedin?: string
    tiktok?: string
    twitter?: string
  }
  website_properties: {
    email_address: string[]
    contact_url: string[]
    phone_number: string[]
  }
}

interface LbLeadInfosProps {
  leadData: GridRowParams<Lead1>
}

export const LbLeadInfos = (props: LbLeadInfosProps) => {
  const { leadData } = props

  if (!leadData) return null

  const {
    databaseId,
    score: rowScore,
    website_properties: websiteProperties,
  } = leadData as unknown as LbLeadData

  const dispatch = useAppDispatch()

  const windowLocation = useLocation()

  const [localDatabaseId, setLocalDatabaseId] = useState(databaseId)

  const {
    lastNavDrawerPartial,
    navDrawerPartial,
    leadHistory,
    currentLensId,
    wishlistViewMode,
  } = useAppSelector(selectCommonsState)

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { data: leadIdPredictStatus, isFetching: leadIdPredictStatusFetching } =
    useGetLensesByLensIdLeadsAndLeadIdPredictStatusQuery(
      {
        lensId: currentLensId,
        leadId: localDatabaseId,
      },
      {
        skip: !currentLensId,
      },
    )

  const { data: leadByIdData, isFetching: leadsByLeadIdFetching } =
    useGetLensesByLensIdLeadsAndLeadIdQuery(
      {
        lensId: currentLensId,
        leadId: localDatabaseId,
      },
      {
        skip: !currentLensId,
      },
    )

  useAsyncEffect(async () => {
    if (
      wishlistViewMode !== WishlistViewMode.TIMELINE &&
      leadByIdData?.score &&
      rowScore &&
      leadByIdData?.score !== rowScore
    ) {
      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    }
  }, [leadByIdData])

  const {
    score,
    highlighted_fields: highlightedFields,
    website,
    location,
    registry_ids,
    last_website_clicked_at,
    social_urls: socialUrls,
    size,
    state,
    type,
    sector,
    keywords,
    logo,
    name,
    saved_at: savedAt,
    description,
    description_source: descriptionSource,
    origin,
  } = leadByIdData ?? {}

  const {
    data: leadIdSimilarPast,
    isFetching: leadsByLeadIdSimilarPastIsFetching,
  } = useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery(
    {
      lensId: currentLensId,
      leadId: localDatabaseId,
    },
    {
      skip: !currentLensId,
    },
  )

  const [localScore, setLocalScore] = useState(0)

  useEffect(() => {
    setLocalDatabaseId(databaseId)

    if (leadHistory.length === 0) {
      dispatch(
        setLeadHistory([
          {
            id: databaseId,
            score,
            highlighted_fields: highlightedFields,
            website,
            location,
            size,
            state,
            sector,
            keywords,
            logo,
            name,
            description,
            description_source: descriptionSource,
            origin,
          } as unknown as Lead1,
          ...leadHistory,
        ]),
      )
    }
  }, [databaseId])

  useLayoutEffect(() => {
    if (!score) {
      setLocalScore(0)

      return
    }

    setLocalScore(score)
  }, [score])

  const items = useMemo(() => {
    const data = []

    if (registry_ids?.SIRENE) {
      data.push({
        type: "SIRENE",
        icon: "🏢",
        value: registry_ids.SIRENE,
      })
    }

    if (website) {
      data.push({
        type: "WEBSITE",
        icon: "🌐",
        value: website,
      })
    }

    if (location?.full) {
      data.push({
        type: "LOCATION",
        icon: "📍",
        value: location.full,
      })
    }

    if (
      (size?.min !== undefined && size?.min !== 0) ||
      (size?.max !== undefined && size?.max !== 0)
    ) {
      let value = ""

      if (size?.min !== undefined && size?.max !== undefined) {
        value = `${size.min} - ${size?.max} employees`
      } else if (size?.min !== undefined) {
        value = `At least ${size.min} employees`
      } else if (size?.max !== undefined) {
        value = `Up to ${size?.max} employees`
      }

      data.push({
        type: "SIZE",
        icon: "👥",
        value: value,
      })
    }

    if (state?.status) {
      data.push({
        type: "STATUS",
        icon: "📈",
        value: state?.status,
      })
    }

    return data
  }, [location, sector, type, size, state])

  const formatUrl = (url: string): string =>
    url.startsWith("http://") ||
    url.startsWith("https://") ||
    url.startsWith("www.")
      ? url
      : `//${url}`

  const handleClose = () => {
    if (!navDrawerPartial) return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: lastNavDrawerPartial,
      }),
    )
  }

  const handleNavigateBack = () => {
    try {
      const lastLeadIndex =
        leadHistory?.length > 1 ? leadHistory?.length - 2 : 0
      const lastLead = leadHistory?.[lastLeadIndex]

      if (!lastLead) return

      const size = lastLead?.size?.min
        ? [lastLead.size.min, lastLead.size?.max]
        : lastLead.size

      dispatch(
        setDrawerData({
          id: lastLead?.id,
          row: {
            databaseId: lastLead?.id,
            score: lastLead?.score,
            highlighted_fields: lastLead?.highlighted_fields,
            website: lastLead?.website,
            location: location?.full,
            size: size ?? null,
            state: lastLead?.state,
            sector: lastLead?.sector,
            type: lastLead?.type,
            keywords: lastLead?.keywords?.map((kw) => ({
              label: kw?.keyword,
              highlighted: kw?.highlighted,
            })),
            logo: lastLead?.logo,
            name: lastLead?.name,
            description: lastLead?.description,
            description_source: lastLead?.description_source,
          },
          columns: [],
        }),
      )

      dispatch(setLeadHistory(leadHistory.slice(0, leadHistory.length - 1)))
    } catch (error) {
      console.error(`Error navigating back: ${error}`)
    }
  }

  const [showAllKeywords, setShowAllKeywords] = useState(false)

  const handleToggleShowAllKeywords = () => {
    setShowAllKeywords(!showAllKeywords)
  }

  const computedKeywords = keywords
    ?.map((keyword) => ({
      label: keyword?.keyword,
      highlighted: keyword?.highlighted,
      importance: keyword?.importance ?? 0,
    }))
    .sort((a, b) => b.importance - a.importance) as Array<{
    label: string
    highlighted: boolean
    importance: number
  }>

  const firstKeywords = computedKeywords?.slice(0, 10)

  const isTrashScreen =
    windowLocation.pathname ===
    WEB_APP_ROUTES.APP + "/" + WEB_APP_ROUTES.PRIVATE.TRASH

  const isFavoritesScreen =
    windowLocation.pathname ===
    WEB_APP_ROUTES.APP + "/" + WEB_APP_ROUTES.PRIVATE.FAVORITES

  const handleWebsiteClickInteraction = async () => {
    if (wishlistViewMode !== WishlistViewMode.TIMELINE) {
      await handleRecordUserInteractions([
        {
          type: "LEAD_WEBSITE_CLICKED",
          lead_id: String(leadData.id),
        },
      ])

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    }
  }

  const [showPredictedStatusExplanation, setShowPredictedStatusExplanation] =
    useState(false)

  const handleTogglePredictedStatusExplanation = () => {
    setShowPredictedStatusExplanation(!showPredictedStatusExplanation)
  }

  const handleOpenLocationOnGoogleMaps = (location: string) => {
    const address = encodeURIComponent(location)
    const url = `https://www.google.com/maps/search/?api=1&query=${address}`
    window.open(url, "_blank")
  }

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)

    toast(`${text} copied to clipboard`)
  }

  if (leadsByLeadIdFetching) return null

  return (
    <LbFade>
      <Box
        className="LbLeadInfos"
        component="article"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 3,
            pb: 3,
          }}
        >
          <LbLeadInfosHeader
            logo={logo}
            name={name}
            website={website}
            origin={origin}
            savedAt={savedAt}
            windowLocation={window.location}
            handleWebsiteClickInteraction={handleWebsiteClickInteraction}
            handleClose={handleClose}
            handleNavigateBack={handleNavigateBack}
            leadHistory={leadHistory}
            isTrashScreen={isTrashScreen}
            isFavoritesScreen={isFavoritesScreen}
            leadData={leadData}
            description={description}
            descriptionSource={descriptionSource}
            wishlistViewMode={wishlistViewMode}
          />

          {leadIdPredictStatus &&
            (leadIdPredictStatusFetching ? (
              <LbLeadInfosStatus
                state={state}
                localScore={localScore}
                leadData={leadData}
                leadIdPredictStatus={null}
                showPredictedStatusExplanation={showPredictedStatusExplanation}
                handleTogglePredictedStatusExplanation={
                  handleTogglePredictedStatusExplanation
                }
              />
            ) : (
              <LbLeadInfosStatus
                state={state}
                localScore={localScore}
                leadData={leadData}
                leadIdPredictStatus={leadIdPredictStatus}
                showPredictedStatusExplanation={showPredictedStatusExplanation}
                handleTogglePredictedStatusExplanation={
                  handleTogglePredictedStatusExplanation
                }
              />
            ))}

          {sector && <LbLeadInfosTags tag={sector} label="Sector" />}

          {type && <LbLeadInfosTags tag={type} label="Type" />}

          {leadIdSimilarPast &&
            !leadsByLeadIdSimilarPastIsFetching &&
            leadIdSimilarPast?.length > 0 && (
              <LbLeadInfosSimilar
                location={location?.full}
                localDatabaseId={localDatabaseId}
              />
            )}

          <LbLeadInfosContact
            websiteProperties={websiteProperties}
            handleCopyToClipboard={handleCopyToClipboard}
          />

          <Box>
            <Typography
              fontWeight={900}
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              Info
            </Typography>

            <Box>
              {items.map(({ value, icon, type }, index) => (
                <LbLeadInfosItems
                  key={index}
                  index={index}
                  icon={icon}
                  type={type}
                  value={value}
                  last_website_clicked_at={last_website_clicked_at}
                  handleWebsiteClickInteraction={handleWebsiteClickInteraction}
                  handleOpenLocationOnGoogleMaps={
                    handleOpenLocationOnGoogleMaps
                  }
                  handleCopyToClipboard={handleCopyToClipboard}
                  formatUrl={formatUrl}
                  formatDateSmall={formatDateSmall}
                  highlightedFields={highlightedFields}
                />
              ))}
              {socialUrls && Object.keys(socialUrls).length > 0 && (
                <LbLeadInfosSocial socialUrls={socialUrls} />
              )}
            </Box>

            {computedKeywords?.length > 0 && (
              <LbLeadInfosKeywords
                showAllKeywords={showAllKeywords}
                computedKeywords={computedKeywords}
                firstKeywords={firstKeywords}
                handleToggleShowAllKeywords={handleToggleShowAllKeywords}
              />
            )}
          </Box>
        </Box>
      </Box>
    </LbFade>
  )
}
