import { yupResolver } from "@hookform/resolvers/yup"
import {
  PostAuthCheckEmailApiArg,
  useGetUsersMeQuery,
  usePostAuthCheckEmailMutation,
  usePostAuthRegisterMutation,
  type AuthInvitesInviteIdGet200Response,
  type PostAuthRegisterApiArg,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { LbButton } from "@components/feedback/LbButton/LbButton"
import LbSecondaryAction from "@components/feedback/LbSecondaryAction/LbSecondaryAction"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import {
  AUTH_TOKEN,
  CENTERED_FLEX_COL_START,
  RESET_PASSWORD_EMAIL,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import {
  selectAuthState,
  setAccessRight,
  setToken,
} from "@leadbay/state/slices/authSlice"
import type { ApiError } from "@leadbay/typings"
import { Box, Checkbox, TextField, Typography } from "@mui/material"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import type * as YupType from "yup"
import * as Yup from "yup"
import { object, string } from "yup"

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
  name: string().required().min(3),
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
  passwordConfirmation: string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Password confirmation is required"),
})

export type RegisterScreenValues = YupType.InferType<typeof validationSchema>

interface RegisterFormProps {
  inviteData?: AuthInvitesInviteIdGet200Response
  invite?: string
}

const RegisterScreen = ({ inviteData, invite }: RegisterFormProps) => {
  const [checked, setChecked] = React.useState(false)
  const [checkboxError, setCheckboxError] = React.useState("")

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      setCheckboxError("")
    }
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [
    checkEmailMutation,
    {
      isLoading: isCheckingEmail,
      isSuccess: isEmailVerified,
      error: emailError,
    },
  ] = usePostAuthCheckEmailMutation()
  const [
    registerMutation,
    { isLoading: isRegistering, isSuccess, error: registerError, isError },
  ] = usePostAuthRegisterMutation()
  const { token } = useAppSelector(selectAuthState)

  const { data: useMeData } = useGetUsersMeQuery(undefined, {
    skip: !token,
  })

  useAsyncEffect(async () => {
    if (useMeData?.verified) {
      console.log("User is verified, redirecting to root")
      navigate(WEB_APP_ROUTES.ROOT)
    }
  }, [useMeData])

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<RegisterScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      name: "",
      password: "",
      passwordConfirmation: "",
    } as RegisterScreenValues,
  })

  const handleRegisterAction = async () => {
    console.log("handleRegisterAction triggered")
    try {
      await localforage.removeItem(RESET_PASSWORD_EMAIL)
      console.log("RESET_PASSWORD_EMAIL removed")
    } catch (error) {
      console.log("Error in handleRegisterAction:", error)
    }
  }

  const handleRegister: SubmitHandler<RegisterScreenValues> = async ({
    password,
    name,
    email,
  }) => {
    console.log("Registering", email, password, name)

    try {
      const payload: PostAuthRegisterApiArg = {
        userRegistration: {
          email,
          password: password as string,
          name,
        },
      }

      const { data } = await registerMutation(payload)
      console.log("Register API response:", data)

      if (!data || !data.token) {
        throw new Error("Registration failed: No token returned.")
      }

      const token = data.token

      if (token) {
        console.log("Dispatching token:", token)
        await localforage.setItem(AUTH_TOKEN, token)
        dispatch(setToken(token))
      }

      navigate(WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.CHECKMAIL)
    } catch (err) {
      console.error("Registration error:", err)
    }
  }

  const handleCheckEmail = async () => {
    await localforage.setItem(RESET_PASSWORD_EMAIL, getValues("email"))
    dispatch(setAccessRight("can_register"))
  }

  const handleFormSubmit: SubmitHandler<RegisterScreenValues> = async ({
    email,
  }) => {
    if (!checked) {
      setCheckboxError("Please agree with the terms of services.")
      return
    }

    try {
      const checkNonProEmail: PostAuthCheckEmailApiArg = {
        checkEmail: {
          email,
        },
      }

      const emailCheckResult =
        await checkEmailMutation(checkNonProEmail).unwrap()

      if (!emailCheckResult) {
        await handleCheckEmail()
        console.log(getValues())

        await handleRegister(getValues())
      }
    } catch (error) {
      console.error(error)
    }
  }

  useApiRequestFeedback({
    isSuccess: isEmailVerified || isSuccess,
    error: emailError || registerError,
    config: {
      onSuccess: {
        action: () => {
          console.log("onSuccess action triggered")
          handleRegisterAction()
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "You already have an account, please login",
          },
        },
        403: {
          message: {
            text: "You don't have beta access to Leadbay, please request an access",
          },
        },
      },
    },
  })

  const statusCode = (emailError as ApiError)?.status

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="register-form">
      <Box
        sx={{
          ...CENTERED_FLEX_COL_START,
          height: "fit-content",
          width: "300px",
          rowGap: "88px",
        }}
      >
        <Box
          sx={{
            ...CENTERED_FLEX_COL_START,
            rowGap: "24px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "21px",
              lineHeight: "27.36px",
            }}
            variant="h6"
            component="h2"
          >
            Create an account.
          </Typography>
          <Box
            sx={{
              ...CENTERED_FLEX_COL_START,
              rowGap: "8px",
              width: "100%",
            }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  error={!!errors.email}
                  type="email"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                    "& .MuiInputBase-input": {
                      height: "48px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderWidth: "1px", // Changez cette valeur pour la taille de la bordure souhaitée
                      },
                  }}
                  helperText={errors.email?.message}
                  label="Email"
                  placeholder="name@company.com"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  error={!!errors.name}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                    "& .MuiInputBase-input": {
                      height: "48px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderWidth: "1px", // Changez cette valeur pour la taille de la bordure souhaitée
                      },
                  }}
                  helperText={errors.name?.message}
                  label="Name"
                  placeholder="Your Name"
                  {...field}
                  required
                />
              )}
            />
          </Box>
          <Box
            sx={{
              ...CENTERED_FLEX_COL_START,
              rowGap: "8px",
              width: "100%",
            }}
          >
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <LbPasswordField
                  label="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  placeholder="********"
                  fullWidth
                  required
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <LbPasswordField
                  label="Confirm password"
                  placeholder="********"
                  fullWidth
                  required
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <Checkbox
                id="terms-and-policy-checkbox"
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "Terms and policy checkbox" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 18 },
                  width: "24px",
                  height: "24px",
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#686171",
                }}
              >
                Agreed to{" "}
                <a
                  href="https://www.leadbay.ai/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Terms of use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.leadbay.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy policy
                </a>
                .
              </Typography>
            </Box>
            {checkboxError && (
              <Typography
                sx={{
                  color: "#FD3434",
                  fontSize: "14px",
                  lineHeight: "18.24px",
                }}
              >
                {checkboxError}
              </Typography>
            )}
          </Box>
          {statusCode === 403 ? (
            <>
              <Typography sx={{ mb: 2 }}>
                You don&apos;t have an access yet, request an access here:
              </Typography>
              <a
                href="https://airtable.com/appAF5Qt556zU5sgB/shrr9Q9SNhkbdJ40u"
                target="_blank"
                rel="noreferrer"
              >
                <LbButton variant="contained" color="primary" size="large">
                  Request access
                </LbButton>
              </a>
            </>
          ) : (
            <>
              <LbButton
                id="register-button-validation"
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                  height: "48px",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#191919",
                  },
                }}
                size="large"
                disabled={!isValid || isCheckingEmail || isRegistering}
                type="submit"
                loading={isCheckingEmail || isRegistering}
              >
                Create account
              </LbButton>
            </>
          )}
        </Box>
        <LbSecondaryAction
          route={WEB_APP_ROUTES.ROOT}
          buttonId="login-button"
          buttonLabel="Log in"
        />
      </Box>
    </form>
  )
}

export default RegisterScreen
