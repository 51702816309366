/* WEB_APP_ROUTES
 *********************************************
 * Definitions for common, public, and private routes
 * Used for application navigation
 *********************************************/

/** Common routes */
export const COMMONS_ROUTES = {
  ALL: "*", // Matches all routes (404 fallback)
  ROOT: "/", // Home page
  APP: "/app", // Main application
}

/** Public routes (accessible without login) */
export const PUBLIC_ROUTES = {
  LOGIN: "login", // Login page
  INVITE_REGISTER: "register", // Registration page for invites
  FORGOT_PASSWORD: "forgot-password", // Password recovery page
  RESET_PASSWORD: "password-reset", // Password reset page
  VERIFY_EMAIL: "verify-email", // Email verification page
  CHANGELOG_SCREEN: "changelog", // Changelog screen
  MAP: "map", // Map view
  CHECKMAIL: "checkmail", // Success register screen
}

/** Private routes (require authentication) */
export const PRIVATE_ROUTES = {
  ONBOARDING: "onboarding", // User onboarding page
  DASHBOARD: "dashboard", // Main dashboard
  ACCOUNT: "account", // Account management
  TRASH: "trash", // Trash section
  ORGANIZATION: "organization", // Organization management
  SUBSCRIPTION: "subscription", // Subscription management
  STRIPE_SUCCESS: "stripe/success", // Stripe payment success
  STRIPE_CANCEL: "stripe/cancel", // Stripe payment cancellation
  INTEGRATIONS: "integrations", // Integrations management
  FAVORITES: "favorite-leads", // Favorite leads section
  OAUTH: "oauth/authorize", // OAuth authorization page
  HUBSPOT_OAUTH_CALLBACK: "oauth/hubspot/callback", // HubSpot OAuth callback
}

/** Complete web app route structure including common, public, and private */
export const WEB_APP_ROUTES = {
  ...COMMONS_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
  PRIVATE: PRIVATE_ROUTES,
}

/** Complete mobile app routes */
export enum MOBILE_APP_ROUTES {
  Root = "/",
  Map = "/map",
  Discover = "/discover",
  Saved = "/saved",
  Monitor = "/monitor",
  Register = "/register",
  Settings = "/settings",
  Trash = "/trash",
  Liked = "/liked",
  LeadProfile = "/lead-profile",
  MapLeadsList = "/map-leads-list",
}

/* INDEXED KEYS
 *********************************************
 * Constants for indexed storage keys
 * Used to store specific application states
 *********************************************/

export const AUTH_TOKEN = "auth_token" // User authentication token
export const RESET_PASSWORD_EMAIL = "reset_password_email" // Email for password reset process
export const MAPPING_STEP = "mapping_step" // Current step in mapping process
export const MAPPING_FIELD = "mapping_field" // Field in mapping process
export const IMPORT_ID = "importId" // Current import identifier
export const CURRENT_LENS_ID = "current_lens_id" // Active lens identifier
export const TOTAL_NEW_LEADS = "total_new_leads" // Total number of new leads
export const SELECTED_IDS = "selected_ids" // IDs of selected items
export const SEEN_LEADS_PERCENTAGE = "seen_leads_percentage" // Percentage of leads viewed
export const INITIAL_COUNT_DATA = "initial_count_data" // Initial data count
export const WISHLIST_DISCOVER_VISIBILITY_MODEL =
  "whishlist_discover_visibility_model" // Wishlist discover visibility setting
export const WISHLIST_MONITOR_VISIBILITY_MODEL =
  "whishlist_monitor_visibility_model" // Wishlist monitor visibility setting

/* LENSES
 *********************************************
 * Constants for lens configurations
 *********************************************/

export const DEFAULT_LENS = "Default" // Default lens setting

/* MUI STYLES
 *********************************************
 * Constants for Material-UI component styling
 *********************************************/

export const APP_NAV_WIDTH = "64px" // Width of app navigation
export const APP_BAR_HEIGHT = "60px" // Height of app bar
export const APP_DRAWER_WIDTH = window.innerWidth > 1460 ? "27vw" : "450px" // Responsive drawer width

/** Centered flex column layout */
export const CENTERED_FLEX_COL = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}

/** Centered flex row layout */
export const CENTERED_FLEX_ROW = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

/** Flex row layout with space between elements */
export const CENTERED_FLEX_ROW_BETWEEN = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}

/** Flex row layout with space around elements */
export const CENTERED_FLEX_ROW_AROUND = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
}

/** Flex row layout with alignment at start */
export const CENTERED_FLEX_ROW_START = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}

/** Flex row layout with alignment at end */
export const CENTERED_FLEX_ROW_END = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
}

/** Centered flex column layout with start alignment */
export const CENTERED_FLEX_COL_START = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}

/** Centered flex column layout with end alignment */
export const CENTERED_FLEX_COL_END = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
}

/** Centered flex column layout with space around */
export const CENTERED_FLEX_COL_AROUND = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
}

/** Centered flex column layout with space between */
export const CENTERED_FLEX_COL_BETWEEN = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
}

/** Flex column layout with space around and start alignment */
export const CENTERED_FLEX_COL_AROUND_START = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-around",
}

/** Flex column layout with space around and end alignment */
export const CENTERED_FLEX_COL_AROUND_END = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "space-around",
}

/** Flex column layout with space between and start alignment */
export const CENTERED_FLEX_COL_BETWEEN_START = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
}

/** Border color for components */
export const BORDERS_COLORS = "rgba(0, 0, 0, 0.08)"

/** Dark theme color palette */
export const DARK_COLORS = {
  primary: {
    main: "#ffffff",
  },
  secondary: {
    main: "#F3F6F5",
  },
  background: {
    default: "#0F0F0F",
    paper: "#151515",
  },
  text: {
    secondary: "#737791",
  },
  success: {
    main: "#61E786",
  },
  error: {
    main: "#e74c3c",
  },
  info: {
    main: "#E1FFE9",
  },
  warning: {
    main: "#FFCDD2",
  },
}

/** Light theme color palette */
export const LIGHT_COLORS = {
  primary: {
    main: "#000000",
  },
  secondary: {
    main: "#F3F6F5",
  },
  background: {
    default: "#ffffff",
    paper: "#FAFAFA",
  },
  text: {
    secondary: "#737791",
  },
  success: {
    main: "#61E786",
  },
  error: {
    main: "#e74c3c",
  },
  info: {
    main: "#E1FFE9",
  },
  warning: {
    main: "#FFCDD2",
  },
}

/** Global Material-UI theme styles */
export const GLOBAL_MUI_STYLES = {
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily:
      "Hanken Grotesk, Reenie Beanie, Basement Grotesque, Arial, sans-serif",
    h1: {
      fontFamily: "Basement Grotesque, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h4: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h5: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h6: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    button: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 500,
      fontSize: "1rem",
    },
  },
}
