import { LbButton } from "@components/feedback/LbButton/LbButton"
import LbSecondaryAction from "@components/feedback/LbSecondaryAction/LbSecondaryAction"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import {
  RESET_PASSWORD_EMAIL,
  WEB_APP_ROUTES,
  CENTERED_FLEX_COL,
} from "@leadbay/constants"
import {
  usePostAuthPasswordResetStartMutation,
  type PostAuthPasswordResetStartApiArg,
} from "@leadbay/state/api"
import { TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import localforage from "localforage"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { object, string } from "yup"

interface ForgotPasswordScreenValues {
  email: string
}

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
})

const ForgotPasswordScreen = () => {
  const [resetPasswordStart, { isLoading, isSuccess, error }] =
    usePostAuthPasswordResetStartMutation()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  })

  const handleFormSubmit: SubmitHandler<ForgotPasswordScreenValues> = async ({
    email,
  }) => {
    try {
      const payload: PostAuthPasswordResetStartApiArg = {
        authPasswordResetStartPostRequest: {
          email,
        },
      }

      await resetPasswordStart(payload)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetTempEmail = async () => {
    try {
      await localforage.setItem(RESET_PASSWORD_EMAIL, getValues("email"))
    } catch (error) {
      console.log(error)
    }
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        action: handleSetTempEmail,
        message: {
          text:
            "A password reset link has been sent to " +
            getValues("email") +
            ". you can close this tab now",
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
      },
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="forgot-password-screen">
      <Box
        sx={{
          ...CENTERED_FLEX_COL,
          width: "300px",
          gap: "88px",
        }}
      >
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            gap: "40px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              mt: 1,
              fontWeight: "700",
              textAlign: "center",
              fontSize: "21px",
              lineHeight: "27.36px",
            }}
            variant="h6"
            component="h2"
          >
            Enter your email below to receive a reset link.
          </Typography>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                error={!!errors.email}
                type="email"
                fullWidth
                helperText={errors.email?.message}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "48px",
                  },
                  "& .MuiInputBase-input": {
                    height: "48px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth: "1px", // Changez cette valeur pour la taille de la bordure souhaitée
                    },
                }}
                label="Email"
                placeholder="name@company.com"
                {...field}
                required
              />
            )}
          />
          <LbButton
            variant="contained"
            color="primary"
            disabled={!isValid || isLoading}
            type="submit"
            sx={{
              textTransform: "capitalize",
              height: "48px",
              width: "100%",
              "&:hover": {
                backgroundColor: "#191919",
              },
            }}
            loading={isLoading}
          >
            Verify email
          </LbButton>
        </Box>
        <LbSecondaryAction
          route={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.LOGIN}
          buttonId="login-button"
          buttonLabel="Log in"
        />
      </Box>
    </form>
  )
}

export default ForgotPasswordScreen
