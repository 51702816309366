import { LbFavoriteModule } from "@components/feedback/LbFavoriteModule/LbFavoriteModule"
import AnimatedNumber from "@crossfox/react-animated-number"
import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { NewableLead } from "@leadbay/state/api"
import { useAppSelector } from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { TransformedEntry } from "@leadbay/utils/commons/generateWishListRows"
import { CloudDownload } from "@mui/icons-material"
import { Chip, LinearProgress, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { GridCellParams, GridColDef } from "@mui/x-data-grid"
import { useInterval } from "ahooks"
import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { LbDatableCell } from "../LbDatatableCell/LbDatatableCell"

interface UseWishlistColumns {
  showLoader?: boolean
  wishlistViewMode: WishlistViewMode
}

export const useWishlistColumns = ({
  showLoader,
  wishlistViewMode,
}: UseWishlistColumns) => {
  const { navDrawerPartial } = useAppSelector(selectCommonsState)

  const columns: ReadonlyArray<
    GridColDef<
      TransformedEntry & {
        new: boolean
      }
    >
  > = useMemo(() => {
    const renderScoreCell = (params: GridCellParams) => {
      const { value, row } = params

      const [localesScore, setLocalesScore] = useState(value)

      useInterval(
        () => {
          if (showLoader) setLocalesScore(Math.floor(Math.random() * 100))
        },
        showLoader ? Math.floor(Math.random() * 500) : undefined,
      )

      useEffect(() => {
        if (!showLoader) {
          setLocalesScore(value)
        }
      }, [showLoader, value])

      const shouldDisplayProgressBar =
        Number(value) > 0 &&
        row.state?.status !== "WON" &&
        row.state?.status !== "LOST"

      return shouldDisplayProgressBar ? (
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            width: "40px",
            margin: "auto",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: "900",
              mb: 0.2,
              fontFamily: "BasementGrotesque",
              color: "primary.main",
            }}
          >
            <AnimatedNumber
              value={localesScore as number}
              duration={300}
              suffix="&nbsp;"
            />
          </Typography>

          <LinearProgress
            color="success"
            sx={{ width: "100%", height: 5 }}
            variant={showLoader ? "indeterminate" : "determinate"}
            value={showLoader ? 100 : (localesScore as number)}
            className={clsx(showLoader && "animate-pulse")}
          />
        </Box>
      ) : null
    }

    const renderNewCell = (params: GridCellParams) => {
      const [isNew, setIsNew] = useState(params.value)

      useEffect(() => {
        const handleRowClick = (event: MouseEvent) => {
          if ((event.target as HTMLElement).closest("button")) {
            return
          }
          setIsNew(false)
        }

        const rowElement = document.querySelector(
          `[data-id="${params.row.id}"]`,
        ) as HTMLElement

        if (!rowElement) return

        rowElement.addEventListener("click", handleRowClick)

        return () => {
          rowElement.removeEventListener("click", handleRowClick)
        }
      }, [])

      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {isNew ? (
            <Box
              sx={[
                (theme) => ({
                  background: theme.palette.success.main,
                  width: 7,
                  height: 7,
                  borderRadius: "100%",
                }),
              ]}
            />
          ) : null}
        </Box>
      )
    }

    const renderNameCell = (params: GridCellParams) => {
      const row = params.row as unknown as NewableLead

      return (
        <Box
          width="100%"
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              ...CENTERED_FLEX_COL,
              height: "20px",
              width: "20px",
            }}
          >
            {row.logo && (
              <img
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                  borderRadius: "5px",
                }}
                src={row.logo}
                alt={params.value as string}
              />
            )}
          </Box>

          <Box sx={{ width: "15px" }} />

          <Typography
            variant="subtitle2"
            overflow="hidden"
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "Hanken Grotesk",
              pl: 1,
            }}
          >
            {params.value as string}
          </Typography>

          {row.origin?.type === "EXPORTED" && (
            <>
              <Box sx={{ width: "8px" }} />

              <CloudDownload
                sx={{
                  fontSize: "1rem",
                }}
              />
            </>
          )}
        </Box>
      )
    }

    const renderStatusCell = (params: GridCellParams) => {
      const status = (params.value as { status: string })?.status

      const renderChip = (
        label: string,
        color: "default" | "info" | "warning",
      ) => (
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            width: "100%",
          }}
        >
          <Chip
            label={<Box sx={{ px: 2 }}>{label}</Box>}
            sx={{
              fontWeight: 700,
              fontSize: "0.7rem",
              paddingLeft: "8px !important",
              paddingRight: "8px !important",
            }}
            color={color}
            size="small"
          />
        </Box>
      )

      if (wishlistViewMode === WishlistViewMode.MONITOR && status) {
        return renderChip(status, "default")
      } else {
        return null
      }
    }

    const renderStatusPredictionCell = (params: GridCellParams) => {
      const statusPrediction = (params.value as { status: string })?.status
      const changed = (params.value as { changed: boolean })?.changed

      return statusPrediction && changed ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            width: "100%",
          }}
        >
          <Typography>{statusPrediction === "WON" ? "⇧" : "⇩"}</Typography>
        </Box>
      ) : null
    }

    const renderLikedCell = (params: GridCellParams) => {
      return <LbFavoriteModule lead={params.row} />
    }

    return [
      {
        field: "score",
        headerName: "Score",
        description: "Lead score based on scoring parameters",
        type: "number",
        sortable: true,
        align: "left",
        headerAlign: "left",
        width: 60,
        renderCell: renderScoreCell,
      },
      {
        field: "new",
        headerName: "New",
        description: "Indicates if the lead has been recently added.",
        sortable: true,
        width: 20,
        align: "center",
        sortComparator: (v1, v2, param1, param2) => {
          const value1 = param1.value ? 1 : 0
          const value2 = param2.value ? 1 : 0
          return value2 - value1
        },
        renderCell: renderNewCell,
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        flex: 1,
        minWidth: 250,
        description: "Lead name",
        renderCell: renderNameCell,
      },
      {
        field: "status",
        headerName: "Status",
        description: "Current lead status",
        sortable: true,
        width: 85,
        renderCell: renderStatusCell,
      },
      {
        field: "type",
        headerName: "Type",
        description: "Lead type",
        width: 85,
        renderCell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        field: "status_prediction",
        headerName: "Change",
        description: "Predicted status change",
        width: 65,
        sortable: false,
        renderCell: renderStatusPredictionCell,
      },
      {
        field: "sector",
        flex: 1,
        headerName: "Sector",
        description: "Lead sector",
        sortable: true,
        renderCell: ({ row, value }) => (
          <LbDatableCell
            type="SECTOR"
            row={row as unknown as NewableLead}
            value={value}
          />
        ),
      },
      {
        field: "location",
        flex: 1,
        headerName: "Location",
        description: "Lead location",
        sortable: false,
        renderCell: ({ row, value }) => (
          <LbDatableCell
            type="LOCATION"
            row={row as unknown as NewableLead}
            value={value}
          />
        ),
      },
      {
        field: "size",
        headerName: "Size",
        description: "Lead company size",
        minWidth: 70,
        sortable: true,
        sortComparator: (v1, v2, param1, param2) => {
          return param2.value?.[1] - param1.value?.[1]
        },
        renderCell: ({ row, value }) => (
          <LbDatableCell
            type="SIZE"
            row={row as unknown as NewableLead}
            value={value}
          />
        ),
      },
      {
        field: "liked",
        headerName: "",
        sortable: true,
        width: 130,
        sortComparator: (v1, v2, param1, param2) => {
          const likedValue1 = param1.value.liked
            ? 1
            : param1.value.disliked
              ? -1
              : 0
          const likedValue2 = param2.value.liked
            ? 1
            : param2.value.disliked
              ? -1
              : 0
          return likedValue2 - likedValue1
        },
        renderCell: renderLikedCell,
      },
    ]
  }, [showLoader, wishlistViewMode])

  return {
    columns,
  }
}
