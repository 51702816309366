import { LbFavoriteModule } from "@components/feedback/LbFavoriteModule/LbFavoriteModule"
import AnimatedNumber from "@crossfox/react-animated-number"
import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { type NewableLead } from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useRecordUserInteractions,
} from "@leadbay/state/hooks"
import {
  NavDrawerState,
  selectCommonsState,
  setSelectedIds,
  toggleNavDrawer,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { TransformedEntry } from "@leadbay/utils/commons/generateWishListRows"
import { Box, Chip, LinearProgress, Typography } from "@mui/material"
import {
  DataGrid,
  GridCellParams,
  GridToolbarProps,
  ToolbarPropsOverrides,
  useGridApiRef,
  type GridColDef,
  type GridRowParams,
} from "@mui/x-data-grid"
import clsx from "clsx"
import { JSXElementConstructor, useCallback, useEffect, useState } from "react"
import { LbDatableCell } from "../LbDatatableCell/LbDatatableCell"

interface LbDataGridProps {
  rows: Array<TransformedEntry & { new: boolean }>
  toolbar?:
    | JSXElementConstructor<GridToolbarProps & ToolbarPropsOverrides>
    | null
    | undefined
  showLoader?: boolean
  firstTableBlockId?: string
}

export const LbTimelineGrid = ({
  firstTableBlockId,
  showLoader,
  rows,
}: LbDataGridProps) => {
  const timelineApiRef = useGridApiRef()

  const dispatch = useAppDispatch()

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { wishlistViewMode, selectedIds } = useAppSelector(selectCommonsState)

  const handleRowClick = useCallback(
    async (dataGrid: GridRowParams) => {
      await handleRecordUserInteractions([
        {
          type: "BLOCK_LEAD_CLICKED",
          lead_id: String(dataGrid.id),
          block_id: String(dataGrid.row.block_id),
        },
      ])

      const tempSelectedIds = [...selectedIds]

      if (dataGrid.row.new) {
        const index = selectedIds.indexOf(dataGrid.id as string)

        if (index === -1) {
          tempSelectedIds.push(dataGrid.id as string)
        }

        dispatch(setSelectedIds(tempSelectedIds))
      }
      dispatch(
        toggleNavDrawer({
          isOpen: true,
          partial: NavDrawerState.LEAD_INFOS,
          data: dataGrid,
        }),
      )
    },
    [dispatch, handleRecordUserInteractions, selectedIds],
  )

  const renderNewCell = (params: GridCellParams) => {
    const [isNew, setIsNew] = useState(params.value)

    useEffect(() => {
      const handleRowClick = () => {
        setIsNew(false)
      }

      const rowElement = document.querySelector(
        `[data-id="${params.row.id}"]`,
      ) as HTMLElement

      if (!rowElement) return

      rowElement.addEventListener("click", handleRowClick)

      return () => {
        rowElement.removeEventListener("click", handleRowClick)
      }
    }, [])

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          cursor: "pointer",
        }}
      >
        {isNew ? (
          <Box
            sx={[
              (theme) => ({
                background: theme.palette.success.main,
                width: 7,
                height: 7,
                borderRadius: "100%",
              }),
            ]}
          />
        ) : null}
      </Box>
    )
  }

  useEffect(() => {
    if (
      !timelineApiRef.current ||
      String(rows[0]?.block_id) !== String(firstTableBlockId)
    )
      return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: NavDrawerState.LEAD_INFOS,
        data: {
          id: rows[0]?.id,
          row: timelineApiRef?.current?.getRow(rows[0]?.id),
          columns: timelineApiRef?.current?.getAllColumns(),
        },
      }),
    )
  }, [timelineApiRef.current])

  const renderLikedCell = (params: GridCellParams) => {
    return <LbFavoriteModule lead={params.row} />
  }

  const columns: ReadonlyArray<
    GridColDef<
      TransformedEntry & {
        new: boolean
      }
    >
  > = [
    {
      field: "score",
      headerName: "Score",
      description: "Lead score based on the scoring parameters",
      type: "number",
      sortable: true,
      align: "center",
      headerAlign: "left",
      width: 80,
      renderCell: (params) => {
        const [localesSore, setLocalScore] = useState(0)

        useEffect(() => {
          let interval: NodeJS.Timeout | null = null

          if (showLoader) {
            let count = 0
            interval = setInterval(() => {
              setLocalScore((prevScore) => {
                const randomChange = Math.floor(Math.random() * 10) + 1
                const newScore =
                  count % 2 === 0
                    ? prevScore + randomChange
                    : prevScore - randomChange
                if (newScore < 0) return prevScore
                if (newScore > 100) return 100
                return newScore
              })
              count += 1
            }, 300)
          } else {
            setLocalScore(params.value)
          }

          return () => {
            if (interval) clearInterval(interval)
          }
        }, [params.value, showLoader])

        return (
          <>
            {params.value > 0 &&
              // @ts-expect-error - status is not always present
              params.row.state?.status !== "WON" &&
              // @ts-expect-error - status is not always present
              params.row.state?.status !== "LOST" && (
                <Box
                  sx={{
                    ...CENTERED_FLEX_COL,
                    width: "40px",
                    margin: "auto",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "900",
                      mb: 0.2,
                      fontFamily: "BasementGrotesque",
                      color: "primary.main",
                    }}
                  >
                    <AnimatedNumber
                      value={localesSore}
                      duration={300}
                      suffix="&nbsp;"
                    />
                  </Typography>

                  <LinearProgress
                    color="success"
                    sx={{ width: "100%", height: 5 }}
                    variant={showLoader ? "indeterminate" : "determinate"}
                    value={showLoader ? 100 : localesSore}
                    className={clsx(showLoader && "animate-pulse")}
                  />
                </Box>
              )}
          </>
        )
      },
    },
    {
      field: "new",
      headerName: "New",
      description: "Indicates if the lead is new",
      sortable: true,
      width: 20,
      align: "center",
      sortComparator: (v1, v2, param1, param2) => {
        const value1 = param1.value ? 1 : 0
        const value2 = param2.value ? 1 : 0
        return value2 - value1
      },
      renderCell: renderNewCell,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      description: "The name of the lead",
      minWidth: 250,
      renderCell: (params) => {
        const row = params.row as unknown as NewableLead

        return (
          <Box
            width="100%"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                ...CENTERED_FLEX_COL,
                height: "20px",
              }}
            >
              {row.logo && (
                <img
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    marginRight: "8px",
                    borderRadius: "5px",
                  }}
                  src={row.logo}
                  alt={params.value}
                />
              )}
            </Box>

            <Typography
              variant="subtitle2"
              width="100%"
              overflow="hidden"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontFamily: "Hanken Grotesk",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      description: "The status of the lead",
      sortable: true,
      width: 85,
      renderCell: (params) => {
        const status = params?.value?.status

        const renderChip = (
          label: string,
          color: "default" | "info" | "warning",
        ) => (
          <Box
            sx={{
              ...CENTERED_FLEX_COL,
              width: "100%",
            }}
          >
            <Chip
              label={<Box sx={{ px: 2 }}>{label}</Box>}
              sx={{
                fontWeight: 700,
                fontSize: "0.7rem",
                paddingLeft: "8px !important",
                paddingRight: "8px !important",
              }}
              color={color}
              size="small"
            />
          </Box>
        )

        if (wishlistViewMode === WishlistViewMode.MONITOR && status) {
          return <>{renderChip(status, "default")}</>
        } else {
          return ""
        }
      },
    },
    {
      field: "status_prediction",
      headerName: "Change",
      description: "Predicted status evolution",
      width: 65,
      sortable: false,
      renderCell: (params) => {
        const statusPrediction = params?.value?.status

        return statusPrediction ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              width: "100%",
            }}
          >
            <Typography>{statusPrediction === "WON" ? "⇧" : "⇩"}</Typography>
          </Box>
        ) : null
      },
    },
    {
      field: "sector",
      flex: 1,
      headerName: "Sector",
      description: "The sector of the lead",
      sortable: true,

      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SECTOR"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "location",
      flex: 1,
      headerName: "Location",
      description: "The location of the lead",
      sortable: false,

      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="LOCATION"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "size",
      headerName: "Size",
      description: "The size of the lead",
      minWidth: 70,
      sortable: true,

      sortComparator: (v1, v2, param1, param2) => {
        return param2.value?.[1] - param1.value?.[1]
      },
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SIZE"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "liked",
      headerName: "",
      description: "Indicates if the lead has been liked.",
      sortable: false,
      width: 150,
      align: "left",
      renderCell: renderLikedCell,
    },
  ]

  return (
    <DataGrid
      apiRef={timelineApiRef}
      keepNonExistentRowsSelected
      onRowClick={handleRowClick}
      disableRowSelectionOnClick
      getRowClassName={() => "lead-row"}
      hideFooter
      initialState={{
        sorting: {
          sortModel: [{ field: "score", sort: "desc" }],
        },
        columns: {
          columnVisibilityModel: {
            score: false,
            type: false,
            status_prediction: false,
            status: false,
          },
        },
      }}
      sx={{
        borderRadius: 0,
        border: 0,
        m: 0,
        backgroundColor: "#fff",
        ".MuiDataGrid-columnHeaderTitle": {
          color: "text.secondary",
        },
        "& .MuiDataGrid-cell[data-field=name]": {
          overflow: "visible",
        },
      }}
      // @ts-expect-error clipboardPaste is an experimental feature
      experimentalFeatures={{ clipboardPaste: true }}
      unstable_ignoreValueFormatterDuringExport
      rowHeight={40}
      getRowSpacing={() => ({
        top: 2,
        bottom: 2,
      })}
      rows={rows}
      columns={columns}
    />
  )
}
